msgid ""
msgstr ""
"POT-Creation-Date: 2024-08-04 21:58+0800\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/components/account-block.jsx:136
msgid "Locked"
msgstr ""

#. placeholder {0}: shortenNumber(statusesCount)
#: src/components/account-block.jsx:142
msgid "Posts: {0}"
msgstr ""

#. placeholder {0}: niceDateTime(lastStatusAt, { hideTime: true, })
#: src/components/account-block.jsx:147
msgid "Last posted: {0}"
msgstr ""

#: src/components/account-block.jsx:162
#: src/components/account-info.jsx:630
msgid "Automated"
msgstr ""

#: src/components/account-block.jsx:169
#: src/components/account-info.jsx:635
#: src/components/status.jsx:585
msgid "Group"
msgstr ""

#: src/components/account-block.jsx:179
msgid "Mutual"
msgstr ""

#: src/components/account-block.jsx:183
#: src/components/account-info.jsx:1725
msgid "Requested"
msgstr ""

#: src/components/account-block.jsx:187
#: src/components/account-info.jsx:1716
msgid "Following"
msgstr ""

#: src/components/account-block.jsx:191
#: src/components/account-info.jsx:1076
msgid "Follows you"
msgstr ""

#: src/components/account-block.jsx:199
msgid "{followersCount, plural, one {# follower} other {# followers}}"
msgstr ""

#: src/components/account-block.jsx:208
#: src/components/account-info.jsx:676
msgid "Verified"
msgstr ""

#. placeholder {0}: niceDateTime(createdAt, { hideTime: true, })
#. placeholder {0}: niceDateTime(createdAt, { hideTime: true, })
#: src/components/account-block.jsx:223
#: src/components/account-info.jsx:776
msgid "Joined <0>{0}</0>"
msgstr ""

#: src/components/account-info.jsx:58
msgid "Forever"
msgstr ""

#: src/components/account-info.jsx:379
msgid "Unable to load account."
msgstr ""

#: src/components/account-info.jsx:387
msgid "Go to account page"
msgstr ""

#: src/components/account-info.jsx:415
#: src/components/account-info.jsx:698
#: src/components/account-info.jsx:728
msgid "Followers"
msgstr ""

#. js-lingui-explicit-id
#: src/components/account-info.jsx:418
#: src/components/account-info.jsx:738
#: src/components/account-info.jsx:755
msgid "following.stats"
msgstr "Following"

#: src/components/account-info.jsx:421
#: src/components/account-info.jsx:772
#: src/pages/account-statuses.jsx:483
#: src/pages/search.jsx:328
#: src/pages/search.jsx:475
msgid "Posts"
msgstr ""

#: src/components/account-info.jsx:429
#: src/components/account-info.jsx:1132
#: src/components/compose.jsx:2679
#: src/components/media-alt-modal.jsx:46
#: src/components/media-modal.jsx:358
#: src/components/status.jsx:1795
#: src/components/status.jsx:1812
#: src/components/status.jsx:2489
#: src/components/status.jsx:2492
#: src/pages/account-statuses.jsx:527
#: src/pages/accounts.jsx:110
#: src/pages/hashtag.jsx:200
#: src/pages/list.jsx:158
#: src/pages/public.jsx:133
#: src/pages/status.jsx:1214
#: src/pages/trending.jsx:472
msgid "More"
msgstr ""

#: src/components/account-info.jsx:441
msgid "<0>{displayName}</0> has indicated that their new account is now:"
msgstr ""

#: src/components/account-info.jsx:586
#: src/components/account-info.jsx:1188
msgid "Handle copied"
msgstr "Handle copied"

#: src/components/account-info.jsx:589
#: src/components/account-info.jsx:1191
msgid "Unable to copy handle"
msgstr "Unable to copy handle"

#: src/components/account-info.jsx:595
msgid "Copy handle"
msgstr ""

#: src/components/account-info.jsx:602
msgid "View profile image"
msgstr ""

#: src/components/account-info.jsx:608
msgid "View profile header"
msgstr ""

#: src/components/account-info.jsx:625
msgid "In Memoriam"
msgstr ""

#: src/components/account-info.jsx:705
#: src/components/account-info.jsx:746
msgid "This user has chosen to not make this information available."
msgstr "This user has chosen to not make this information available."

#. placeholder {0}: ( postingStats.originals / postingStats.total ).toLocaleString(i18n.locale || undefined, { style: 'percent', })
#. placeholder {1}: ( postingStats.replies / postingStats.total ).toLocaleString(i18n.locale || undefined, { style: 'percent', })
#. placeholder {2}: ( postingStats.boosts / postingStats.total ).toLocaleString(i18n.locale || undefined, { style: 'percent', })
#: src/components/account-info.jsx:801
msgid "{0} original posts, {1} replies, {2} boosts"
msgstr "{0} original posts, {1} replies, {2} boosts"

#. placeholder {0}: postingStats.total
#. placeholder {1}: postingStats.daysSinceLastPost
#. placeholder {2}: postingStats.daysSinceLastPost
#. placeholder {3}: postingStats.daysSinceLastPost
#. placeholder {4}: postingStats.total
#. placeholder {5}: postingStats.total
#. placeholder {6}: postingStats.daysSinceLastPost
#: src/components/account-info.jsx:817
msgid "{0, plural, one {{1, plural, one {Last 1 post in the past 1 day} other {Last 1 post in the past {2} days}}} other {{3, plural, one {Last {4} posts in the past 1 day} other {Last {5} posts in the past {6} days}}}}"
msgstr ""

#. placeholder {0}: postingStats.total
#. placeholder {1}: postingStats.total
#: src/components/account-info.jsx:830
msgid "{0, plural, one {Last 1 post in the past year(s)} other {Last {1} posts in the past year(s)}}"
msgstr ""

#: src/components/account-info.jsx:854
#: src/pages/catchup.jsx:70
msgid "Original"
msgstr ""

#: src/components/account-info.jsx:858
#: src/components/status.jsx:2258
#: src/pages/catchup.jsx:71
#: src/pages/catchup.jsx:1445
#: src/pages/catchup.jsx:2058
#: src/pages/status.jsx:937
#: src/pages/status.jsx:1560
msgid "Replies"
msgstr ""

#: src/components/account-info.jsx:862
#: src/pages/catchup.jsx:72
#: src/pages/catchup.jsx:1447
#: src/pages/catchup.jsx:2070
#: src/pages/settings.jsx:1224
msgid "Boosts"
msgstr ""

#: src/components/account-info.jsx:868
msgid "Post stats unavailable."
msgstr ""

#: src/components/account-info.jsx:899
msgid "View post stats"
msgstr ""

#. placeholder {0}: niceDateTime(lastStatusAt, { hideTime: true, })
#: src/components/account-info.jsx:1080
msgid "Last post: <0>{0}</0>"
msgstr ""

#: src/components/account-info.jsx:1094
msgid "Muted"
msgstr ""

#: src/components/account-info.jsx:1099
msgid "Blocked"
msgstr ""

#: src/components/account-info.jsx:1108
msgid "Private note"
msgstr "Private note"

#: src/components/account-info.jsx:1165
msgid "Mention"
msgstr "Mention"

#: src/components/account-info.jsx:1175
msgid "Translate bio"
msgstr ""

#: src/components/account-info.jsx:1202
msgid "Handle"
msgstr "Handle"

#: src/components/account-info.jsx:1218
msgid "Permalink copied"
msgstr "Permalink copied"

#: src/components/account-info.jsx:1221
msgid "Unable to copy permalink"
msgstr "Unable to copy permalink"

#: src/components/account-info.jsx:1233
msgid "This page"
msgstr "This page"

#: src/components/account-info.jsx:1248
#: src/components/status.jsx:1222
msgid "On your server"
msgstr "On your server"

#: src/components/account-info.jsx:1264
#: src/components/status.jsx:1238
msgid "On the original server"
msgstr "On the original server"

#: src/components/account-info.jsx:1281
msgid "Edit private note"
msgstr "Edit private note"

#: src/components/account-info.jsx:1281
msgid "Add private note"
msgstr "Add private note"

#: src/components/account-info.jsx:1297
#: src/components/account-info.jsx:1921
msgid "Add/Remove from Lists"
msgstr ""

#: src/components/account-info.jsx:1307
msgid "Add to list"
msgstr "Add to list"

#: src/components/account-info.jsx:1331
msgid "Notifications enabled for @{username}'s posts."
msgstr "Notifications enabled for @{username}'s posts."

#: src/components/account-info.jsx:1332
msgid " Notifications disabled for @{username}'s posts."
msgstr " Notifications disabled for @{username}'s posts."

#: src/components/account-info.jsx:1344
msgid "Disable post notifications"
msgstr "Disable post notifications"

#: src/components/account-info.jsx:1345
msgid "Enable post notifications"
msgstr "Enable post notifications"

#: src/components/account-info.jsx:1362
msgid "Boosts from @{username} muted."
msgstr "Boosts from @{username} muted."

#: src/components/account-info.jsx:1363
msgid "Boosts from @{username} unmuted."
msgstr "Boosts from @{username} unmuted."

#: src/components/account-info.jsx:1374
msgid "Mute boosts"
msgstr "Mute boosts"

#: src/components/account-info.jsx:1374
msgid "Unmute boosts"
msgstr "Unmute boosts"

#: src/components/account-info.jsx:1391
msgid "Unmuted @{username}"
msgstr "Unmuted @{username}"

#: src/components/account-info.jsx:1403
msgid "Unmute <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1419
msgid "Mute <0>@{username}</0>…"
msgstr ""

#. placeholder {0}: typeof MUTE_DURATIONS_LABELS[duration] === 'function' ? MUTE_DURATIONS_LABELS[duration]() : _(MUTE_DURATIONS_LABELS[duration])
#: src/components/account-info.jsx:1451
msgid "Muted @{username} for {0}"
msgstr "Muted @{username} for {0}"

#: src/components/account-info.jsx:1463
msgid "Unable to mute @{username}"
msgstr "Unable to mute @{username}"

#: src/components/account-info.jsx:1490
msgid "Remove <0>@{username}</0> from followers?"
msgstr ""

#: src/components/account-info.jsx:1510
msgid "@{username} removed from followers"
msgstr "@{username} removed from followers"

#: src/components/account-info.jsx:1522
msgid "Remove follower…"
msgstr ""

#: src/components/account-info.jsx:1533
msgid "Block <0>@{username}</0>?"
msgstr ""

#: src/components/account-info.jsx:1557
msgid "Unblocked @{username}"
msgstr "Unblocked @{username}"

#: src/components/account-info.jsx:1565
msgid "Blocked @{username}"
msgstr "Blocked @{username}"

#: src/components/account-info.jsx:1573
msgid "Unable to unblock @{username}"
msgstr "Unable to unblock @{username}"

#: src/components/account-info.jsx:1575
msgid "Unable to block @{username}"
msgstr "Unable to block @{username}"

#: src/components/account-info.jsx:1585
msgid "Unblock <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1594
msgid "Block <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1611
msgid "Report <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1631
#: src/components/account-info.jsx:2156
msgid "Edit profile"
msgstr ""

#: src/components/account-info.jsx:1667
msgid "Withdraw follow request?"
msgstr "Withdraw follow request?"

#. placeholder {1}: info.acct || info.username
#: src/components/account-info.jsx:1668
msgid "Unfollow @{1}?"
msgstr "Unfollow @{1}?"

#: src/components/account-info.jsx:1719
msgid "Unfollow…"
msgstr ""

#: src/components/account-info.jsx:1728
msgid "Withdraw…"
msgstr ""

#: src/components/account-info.jsx:1735
#: src/components/account-info.jsx:1739
#: src/pages/hashtag.jsx:262
msgid "Follow"
msgstr ""

#: src/components/account-info.jsx:1861
#: src/components/account-info.jsx:1916
#: src/components/account-info.jsx:2050
#: src/components/account-info.jsx:2151
#: src/components/account-sheet.jsx:38
#: src/components/compose.jsx:870
#: src/components/compose.jsx:2635
#: src/components/compose.jsx:3109
#: src/components/compose.jsx:3318
#: src/components/compose.jsx:3548
#: src/components/drafts.jsx:59
#: src/components/embed-modal.jsx:13
#: src/components/generic-accounts.jsx:143
#: src/components/keyboard-shortcuts-help.jsx:40
#: src/components/list-add-edit.jsx:36
#: src/components/media-alt-modal.jsx:34
#: src/components/media-modal.jsx:322
#: src/components/notification-service.jsx:157
#: src/components/report-modal.jsx:75
#: src/components/shortcuts-settings.jsx:266
#: src/components/shortcuts-settings.jsx:619
#: src/components/shortcuts-settings.jsx:819
#: src/components/status.jsx:2992
#: src/components/status.jsx:3232
#: src/components/status.jsx:3736
#: src/pages/accounts.jsx:37
#: src/pages/catchup.jsx:1581
#: src/pages/filters.jsx:224
#: src/pages/list.jsx:276
#: src/pages/notifications.jsx:915
#: src/pages/settings.jsx:78
#: src/pages/status.jsx:1301
msgid "Close"
msgstr ""

#: src/components/account-info.jsx:1866
msgid "Translated Bio"
msgstr ""

#: src/components/account-info.jsx:1961
msgid "Unable to remove from list."
msgstr "Unable to remove from list."

#: src/components/account-info.jsx:1962
msgid "Unable to add to list."
msgstr "Unable to add to list."

#: src/components/account-info.jsx:1981
#: src/pages/lists.jsx:105
msgid "Unable to load lists."
msgstr ""

#: src/components/account-info.jsx:1985
msgid "No lists."
msgstr ""

#: src/components/account-info.jsx:1996
#: src/components/list-add-edit.jsx:40
#: src/pages/lists.jsx:59
msgid "New list"
msgstr ""

#. placeholder {0}: account?.username || account?.acct
#: src/components/account-info.jsx:2055
msgid "Private note about <0>@{0}</0>"
msgstr ""

#: src/components/account-info.jsx:2085
msgid "Unable to update private note."
msgstr "Unable to update private note."

#: src/components/account-info.jsx:2108
#: src/components/account-info.jsx:2279
msgid "Cancel"
msgstr ""

#: src/components/account-info.jsx:2113
msgid "Save & close"
msgstr ""

#: src/components/account-info.jsx:2207
msgid "Unable to update profile."
msgstr "Unable to update profile."

#: src/components/account-info.jsx:2214
#: src/components/list-add-edit.jsx:105
msgid "Name"
msgstr ""

#: src/components/account-info.jsx:2227
msgid "Bio"
msgstr ""

#: src/components/account-info.jsx:2240
msgid "Extra fields"
msgstr ""

#: src/components/account-info.jsx:2246
msgid "Label"
msgstr ""

#: src/components/account-info.jsx:2249
msgid "Content"
msgstr ""

#: src/components/account-info.jsx:2282
#: src/components/list-add-edit.jsx:150
#: src/components/shortcuts-settings.jsx:751
#: src/pages/filters.jsx:554
#: src/pages/notifications.jsx:981
msgid "Save"
msgstr ""

#: src/components/account-info.jsx:2336
msgid "username"
msgstr ""

#: src/components/account-info.jsx:2340
msgid "server domain name"
msgstr ""

#: src/components/background-service.jsx:151
msgid "Cloak mode disabled"
msgstr ""

#: src/components/background-service.jsx:151
msgid "Cloak mode enabled"
msgstr ""

#: src/components/columns.jsx:28
#: src/components/nav-menu.jsx:180
#: src/components/shortcuts-settings.jsx:155
#: src/components/timeline.jsx:439
#: src/pages/catchup.jsx:876
#: src/pages/filters.jsx:89
#: src/pages/followed-hashtags.jsx:41
#: src/pages/home.jsx:53
#: src/pages/notifications.jsx:560
msgid "Home"
msgstr ""

#: src/components/compose-button.jsx:50
#: src/compose.jsx:38
msgid "Compose"
msgstr ""

#: src/components/compose.jsx:78
msgid "Plain text"
msgstr "Plain text"

#: src/components/compose.jsx:79
msgid "HTML"
msgstr "HTML"

#: src/components/compose.jsx:80
msgid "Markdown"
msgstr "Markdown"

#: src/components/compose.jsx:81
msgid "BBCode"
msgstr "BBCode"

#: src/components/compose.jsx:82
msgid "MFM"
msgstr "MFM"

#: src/components/compose.jsx:213
msgid "Add media"
msgstr "Add media"

#: src/components/compose.jsx:214
msgid "Add custom emoji"
msgstr ""

#: src/components/compose.jsx:215
msgid "Add GIF"
msgstr "Add GIF"

#: src/components/compose.jsx:216
msgid "Add poll"
msgstr ""

#: src/components/compose.jsx:413
msgid "You have unsaved changes. Discard this post?"
msgstr "You have unsaved changes. Discard this post?"

#. placeholder {0}: unsupportedFiles.length
#. placeholder {1}: unsupportedFiles[0].name
#. placeholder {2}: lf.format( unsupportedFiles.map((f) => f.name), )
#: src/components/compose.jsx:641
msgid "{0, plural, one {File {1} is not supported.} other {Files {2} are not supported.}}"
msgstr "{0, plural, one {File {1} is not supported.} other {Files {2} are not supported.}}"

#: src/components/compose.jsx:651
#: src/components/compose.jsx:669
#: src/components/compose.jsx:1729
#: src/components/compose.jsx:1815
msgid "{maxMediaAttachments, plural, one {You can only attach up to 1 file.} other {You can only attach up to # files.}}"
msgstr ""

#: src/components/compose.jsx:851
msgid "Pop out"
msgstr "Pop out"

#: src/components/compose.jsx:858
msgid "Minimize"
msgstr "Minimize"

#: src/components/compose.jsx:894
msgid "Looks like you closed the parent window."
msgstr "Looks like you closed the parent window."

#: src/components/compose.jsx:901
msgid "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."
msgstr "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."

#: src/components/compose.jsx:906
msgid "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"
msgstr "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"

#: src/components/compose.jsx:948
msgid "Pop in"
msgstr "Pop in"

#. placeholder {0}: replyToStatus.account.acct || replyToStatus.account.username
#. placeholder {1}: rtf.format(-replyToStatusMonthsAgo, 'month')
#: src/components/compose.jsx:958
msgid "Replying to @{0}’s post (<0>{1}</0>)"
msgstr ""

#. placeholder {0}: replyToStatus.account.acct || replyToStatus.account.username
#: src/components/compose.jsx:968
msgid "Replying to @{0}’s post"
msgstr ""

#: src/components/compose.jsx:981
msgid "Editing source post"
msgstr ""

#: src/components/compose.jsx:1028
msgid "Poll must have at least 2 options"
msgstr "Poll must have at least 2 options"

#: src/components/compose.jsx:1032
msgid "Some poll choices are empty"
msgstr "Some poll choices are empty"

#: src/components/compose.jsx:1045
msgid "Some media have no descriptions. Continue?"
msgstr "Some media have no descriptions. Continue?"

#: src/components/compose.jsx:1097
msgid "Attachment #{i} failed"
msgstr "Attachment #{i} failed"

#: src/components/compose.jsx:1198
#: src/components/status.jsx:2045
#: src/components/timeline.jsx:988
msgid "Content warning"
msgstr ""

#: src/components/compose.jsx:1214
msgid "Content warning or sensitive media"
msgstr "Content warning or sensitive media"

#: src/components/compose.jsx:1281
#: src/components/status.jsx:93
#: src/pages/settings.jsx:306
msgid "Public"
msgstr ""

#: src/components/compose.jsx:1286
#: src/components/nav-menu.jsx:342
#: src/components/shortcuts-settings.jsx:183
#: src/components/status.jsx:94
msgid "Local"
msgstr ""

#: src/components/compose.jsx:1290
#: src/components/status.jsx:95
#: src/pages/settings.jsx:309
msgid "Unlisted"
msgstr ""

#: src/components/compose.jsx:1293
#: src/components/status.jsx:96
#: src/pages/settings.jsx:312
msgid "Followers only"
msgstr ""

#: src/components/compose.jsx:1298
msgid "List (alpha)"
msgstr "List (alpha)"

#: src/components/compose.jsx:1302
#: src/components/status.jsx:98
msgid "Private mention"
msgstr ""

#: src/components/compose.jsx:1311
msgid "Post your reply"
msgstr "Post your reply"

#: src/components/compose.jsx:1313
msgid "Edit your post"
msgstr "Edit your post"

#: src/components/compose.jsx:1314
msgid "What are you doing?"
msgstr "What are you doing?"

#: src/components/compose.jsx:1392
msgid "Mark media as sensitive"
msgstr ""

#: src/components/compose.jsx:1436
#: src/components/compose.jsx:3167
#: src/components/shortcuts-settings.jsx:751
#: src/pages/list.jsx:362
msgid "Add"
msgstr ""

#: src/components/compose.jsx:1610
#: src/components/keyboard-shortcuts-help.jsx:152
#: src/components/status.jsx:1007
#: src/components/status.jsx:1775
#: src/components/status.jsx:1776
#: src/components/status.jsx:2385
msgid "Reply"
msgstr ""

#: src/components/compose.jsx:1612
msgid "Update"
msgstr "Update"

#: src/components/compose.jsx:1613
msgctxt "Submit button in composer"
msgid "Post"
msgstr "Post"

#: src/components/compose.jsx:1741
msgid "Downloading GIF…"
msgstr "Downloading GIF…"

#: src/components/compose.jsx:1769
msgid "Failed to download GIF"
msgstr "Failed to download GIF"

#: src/components/compose.jsx:1939
#: src/components/compose.jsx:2016
#: src/components/nav-menu.jsx:243
msgid "More…"
msgstr ""

#: src/components/compose.jsx:2448
msgid "Uploaded"
msgstr ""

#: src/components/compose.jsx:2461
msgid "Image description"
msgstr "Image description"

#: src/components/compose.jsx:2462
msgid "Video description"
msgstr "Video description"

#: src/components/compose.jsx:2463
msgid "Audio description"
msgstr "Audio description"

#. placeholder {0}: prettyBytes( imageSize, )
#. placeholder {1}: prettyBytes(imageSizeLimit)
#: src/components/compose.jsx:2499
msgid "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."
msgstr "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."

#. placeholder {2}: i18n.number( width, )
#. placeholder {3}: i18n.number(height)
#. placeholder {4}: i18n.number(newWidth)
#. placeholder {5}: i18n.number( newHeight, )
#: src/components/compose.jsx:2511
msgid "Dimension too large. Uploading might encounter issues. Try reduce dimension from {2}×{3}px to {4}×{5}px."
msgstr "Dimension too large. Uploading might encounter issues. Try reduce dimension from {2}×{3}px to {4}×{5}px."

#. placeholder {6}: prettyBytes( videoSize, )
#. placeholder {7}: prettyBytes(videoSizeLimit)
#: src/components/compose.jsx:2519
msgid "File size too large. Uploading might encounter issues. Try reduce the file size from {6} to {7} or lower."
msgstr "File size too large. Uploading might encounter issues. Try reduce the file size from {6} to {7} or lower."

#. placeholder {8}: i18n.number( width, )
#. placeholder {9}: i18n.number(height)
#. placeholder {10}: i18n.number(newWidth)
#. placeholder {11}: i18n.number( newHeight, )
#: src/components/compose.jsx:2531
msgid "Dimension too large. Uploading might encounter issues. Try reduce dimension from {8}×{9}px to {10}×{11}px."
msgstr "Dimension too large. Uploading might encounter issues. Try reduce dimension from {8}×{9}px to {10}×{11}px."

#: src/components/compose.jsx:2539
msgid "Frame rate too high. Uploading might encounter issues."
msgstr "Frame rate too high. Uploading might encounter issues."

#: src/components/compose.jsx:2599
#: src/components/compose.jsx:2849
#: src/components/shortcuts-settings.jsx:762
#: src/pages/catchup.jsx:1074
#: src/pages/filters.jsx:412
msgid "Remove"
msgstr ""

#: src/components/compose.jsx:2616
#: src/compose.jsx:84
msgid "Error"
msgstr ""

#: src/components/compose.jsx:2641
msgid "Edit image description"
msgstr "Edit image description"

#: src/components/compose.jsx:2642
msgid "Edit video description"
msgstr "Edit video description"

#: src/components/compose.jsx:2643
msgid "Edit audio description"
msgstr "Edit audio description"

#: src/components/compose.jsx:2688
#: src/components/compose.jsx:2737
msgid "Generating description. Please wait…"
msgstr "Generating description. Please wait…"

#. placeholder {12}: e.message
#: src/components/compose.jsx:2708
msgid "Failed to generate description: {12}"
msgstr "Failed to generate description: {12}"

#: src/components/compose.jsx:2709
msgid "Failed to generate description"
msgstr "Failed to generate description"

#: src/components/compose.jsx:2721
#: src/components/compose.jsx:2727
#: src/components/compose.jsx:2773
msgid "Generate description…"
msgstr ""

#. placeholder {13}: e?.message ? `: ${e.message}` : ''
#: src/components/compose.jsx:2760
msgid "Failed to generate description{13}"
msgstr "Failed to generate description{13}"

#. placeholder {0}: localeCode2Text(lang)
#: src/components/compose.jsx:2775
msgid "({0}) <0>— experimental</0>"
msgstr ""

#: src/components/compose.jsx:2794
msgid "Done"
msgstr ""

#. placeholder {0}: i + 1
#: src/components/compose.jsx:2830
msgid "Choice {0}"
msgstr "Choice {0}"

#: src/components/compose.jsx:2877
msgid "Multiple choices"
msgstr ""

#: src/components/compose.jsx:2880
msgid "Duration"
msgstr ""

#: src/components/compose.jsx:2911
msgid "Remove poll"
msgstr ""

#: src/components/compose.jsx:3126
msgid "Search accounts"
msgstr "Search accounts"

#: src/components/compose.jsx:3180
#: src/components/generic-accounts.jsx:228
msgid "Error loading accounts"
msgstr ""

#: src/components/compose.jsx:3324
msgid "Custom emojis"
msgstr ""

#: src/components/compose.jsx:3344
msgid "Search emoji"
msgstr "Search emoji"

#: src/components/compose.jsx:3375
msgid "Error loading custom emojis"
msgstr ""

#: src/components/compose.jsx:3386
msgid "Recently used"
msgstr "Recently used"

#: src/components/compose.jsx:3387
msgid "Others"
msgstr "Others"

#. placeholder {0}: i18n.number(emojis.length - max)
#: src/components/compose.jsx:3425
msgid "{0} more…"
msgstr ""

#: src/components/compose.jsx:3563
msgid "Search GIFs"
msgstr "Search GIFs"

#: src/components/compose.jsx:3578
msgid "Powered by GIPHY"
msgstr "Powered by GIPHY"

#: src/components/compose.jsx:3586
msgid "Type to search GIFs"
msgstr ""

#: src/components/compose.jsx:3684
#: src/components/media-modal.jsx:462
#: src/components/timeline.jsx:893
msgid "Previous"
msgstr ""

#: src/components/compose.jsx:3702
#: src/components/media-modal.jsx:481
#: src/components/timeline.jsx:910
msgid "Next"
msgstr ""

#: src/components/compose.jsx:3719
msgid "Error loading GIFs"
msgstr ""

#: src/components/drafts.jsx:64
#: src/pages/settings.jsx:737
msgid "Unsent drafts"
msgstr ""

#: src/components/drafts.jsx:69
msgid "Looks like you have unsent drafts. Let's continue where you left off."
msgstr ""

#: src/components/drafts.jsx:103
msgid "Delete this draft?"
msgstr ""

#: src/components/drafts.jsx:118
msgid "Error deleting draft! Please try again."
msgstr ""

#: src/components/drafts.jsx:128
#: src/components/list-add-edit.jsx:186
#: src/components/status.jsx:1409
#: src/pages/filters.jsx:587
msgid "Delete…"
msgstr ""

#: src/components/drafts.jsx:147
msgid "Error fetching reply-to status!"
msgstr ""

#: src/components/drafts.jsx:172
msgid "Delete all drafts?"
msgstr ""

#: src/components/drafts.jsx:190
msgid "Error deleting drafts! Please try again."
msgstr ""

#: src/components/drafts.jsx:202
msgid "Delete all…"
msgstr ""

#: src/components/drafts.jsx:210
msgid "No drafts found."
msgstr ""

#: src/components/drafts.jsx:247
#: src/pages/catchup.jsx:1929
msgid "Poll"
msgstr ""

#: src/components/drafts.jsx:250
#: src/pages/account-statuses.jsx:364
msgid "Media"
msgstr ""

#: src/components/embed-modal.jsx:23
msgid "Open in new window"
msgstr ""

#: src/components/follow-request-buttons.jsx:43
#: src/pages/notifications.jsx:965
msgid "Accept"
msgstr ""

#: src/components/follow-request-buttons.jsx:69
msgid "Reject"
msgstr ""

#: src/components/follow-request-buttons.jsx:76
#: src/pages/notifications.jsx:1249
msgid "Accepted"
msgstr ""

#: src/components/follow-request-buttons.jsx:80
msgid "Rejected"
msgstr ""

#: src/components/generic-accounts.jsx:146
#: src/components/notification.jsx:463
#: src/pages/accounts.jsx:42
#: src/pages/search.jsx:318
#: src/pages/search.jsx:351
msgid "Accounts"
msgstr ""

#: src/components/generic-accounts.jsx:206
#: src/components/timeline.jsx:521
#: src/pages/list.jsx:295
#: src/pages/notifications.jsx:895
#: src/pages/search.jsx:545
#: src/pages/status.jsx:1334
msgid "Show more…"
msgstr ""

#: src/components/generic-accounts.jsx:211
#: src/components/timeline.jsx:526
#: src/pages/search.jsx:550
msgid "The end."
msgstr ""

#: src/components/generic-accounts.jsx:232
msgid "Nothing to show"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:44
#: src/components/nav-menu.jsx:369
#: src/pages/catchup.jsx:1619
msgid "Keyboard shortcuts"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:52
msgid "Keyboard shortcuts help"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:56
#: src/pages/catchup.jsx:1644
msgid "Next post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:60
#: src/pages/catchup.jsx:1652
msgid "Previous post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:64
msgid "Skip carousel to next post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:66
msgid "<0>Shift</0> + <1>j</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:72
msgid "Skip carousel to previous post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:74
msgid "<0>Shift</0> + <1>k</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:80
msgid "Load new posts"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:84
#: src/pages/catchup.jsx:1676
msgid "Open post details"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:86
msgid "<0>Enter</0> or <1>o</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:93
msgid "Expand content warning or<0/>toggle expanded/collapsed thread"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:102
msgid "Close post or dialogs"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:104
msgid "<0>Esc</0> or <1>Backspace</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:110
msgid "Focus column in multi-column mode"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:112
msgid "<0>1</0> to <1>9</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:118
msgid "Focus next column in multi-column mode"
msgstr "Focus next column in multi-column mode"

#: src/components/keyboard-shortcuts-help.jsx:122
msgid "Focus previous column in multi-column mode"
msgstr "Focus previous column in multi-column mode"

#: src/components/keyboard-shortcuts-help.jsx:126
msgid "Compose new post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:130
msgid "Compose new post (new window)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:133
msgid "<0>Shift</0> + <1>c</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:139
msgid "Send post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:141
msgid "<0>Ctrl</0> + <1>Enter</1> or <2>⌘</2> + <3>Enter</3>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:148
#: src/components/nav-menu.jsx:330
#: src/components/search-form.jsx:73
#: src/components/shortcuts-settings.jsx:51
#: src/components/shortcuts-settings.jsx:212
#: src/pages/search.jsx:46
#: src/pages/search.jsx:300
msgid "Search"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:156
msgid "Reply (new window)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:159
msgid "<0>Shift</0> + <1>r</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:165
msgid "Like (favourite)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:167
msgid "<0>l</0> or <1>f</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:173
#: src/components/status.jsx:1015
#: src/components/status.jsx:2412
#: src/components/status.jsx:2443
#: src/components/status.jsx:2444
msgid "Boost"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:175
msgid "<0>Shift</0> + <1>b</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:181
#: src/components/status.jsx:1100
#: src/components/status.jsx:2468
#: src/components/status.jsx:2469
msgid "Bookmark"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:185
msgid "Toggle Cloak mode"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:187
msgid "<0>Shift</0> + <1>Alt</1> + <2>k</2>"
msgstr ""

#: src/components/list-add-edit.jsx:40
msgid "Edit list"
msgstr ""

#: src/components/list-add-edit.jsx:96
msgid "Unable to edit list."
msgstr ""

#: src/components/list-add-edit.jsx:97
msgid "Unable to create list."
msgstr ""

#: src/components/list-add-edit.jsx:125
msgid "Show replies to list members"
msgstr ""

#: src/components/list-add-edit.jsx:128
msgid "Show replies to people I follow"
msgstr ""

#: src/components/list-add-edit.jsx:131
msgid "Don't show replies"
msgstr ""

#: src/components/list-add-edit.jsx:144
msgid "Hide posts on this list from Home/Following"
msgstr ""

#: src/components/list-add-edit.jsx:150
#: src/pages/filters.jsx:554
msgid "Create"
msgstr ""

#: src/components/list-add-edit.jsx:157
msgid "Delete this list?"
msgstr ""

#: src/components/list-add-edit.jsx:176
msgid "Unable to delete list."
msgstr ""

#: src/components/media-alt-modal.jsx:39
#: src/components/media.jsx:51
msgid "Media description"
msgstr ""

#: src/components/media-alt-modal.jsx:58
#: src/components/status.jsx:1144
#: src/components/status.jsx:1171
#: src/components/translation-block.jsx:196
msgid "Translate"
msgstr ""

#: src/components/media-alt-modal.jsx:69
#: src/components/status.jsx:1158
#: src/components/status.jsx:1185
msgid "Speak"
msgstr ""

#: src/components/media-modal.jsx:369
msgid "Open original media in new window"
msgstr ""

#: src/components/media-modal.jsx:373
msgid "Open original media"
msgstr ""

#: src/components/media-modal.jsx:389
msgid "Attempting to describe image. Please wait…"
msgstr ""

#: src/components/media-modal.jsx:404
msgid "Failed to describe image"
msgstr ""

#: src/components/media-modal.jsx:414
msgid "Describe image…"
msgstr ""

#: src/components/media-modal.jsx:437
msgid "View post"
msgstr ""

#: src/components/media-post.jsx:128
msgid "Sensitive media"
msgstr ""

#: src/components/media-post.jsx:133
msgid "Filtered: {filterTitleStr}"
msgstr ""

#: src/components/media-post.jsx:134
#: src/components/status.jsx:3566
#: src/components/timeline.jsx:977
#: src/pages/catchup.jsx:75
#: src/pages/catchup.jsx:1877
msgid "Filtered"
msgstr ""

#: src/components/modals.jsx:73
msgid "Post published. Check it out."
msgstr ""

#: src/components/modals.jsx:74
msgid "Reply posted. Check it out."
msgstr ""

#: src/components/modals.jsx:75
msgid "Post updated. Check it out."
msgstr ""

#: src/components/nav-menu.jsx:122
msgid "Menu"
msgstr ""

#: src/components/nav-menu.jsx:158
msgid "Reload page now to update?"
msgstr ""

#: src/components/nav-menu.jsx:170
msgid "New update available…"
msgstr ""

#. js-lingui-explicit-id
#: src/components/nav-menu.jsx:189
#: src/components/shortcuts-settings.jsx:156
#: src/pages/following.jsx:23
#: src/pages/following.jsx:142
msgid "following.title"
msgstr "Following"

#: src/components/nav-menu.jsx:196
#: src/pages/catchup.jsx:871
msgid "Catch-up"
msgstr ""

#: src/components/nav-menu.jsx:203
#: src/components/shortcuts-settings.jsx:57
#: src/components/shortcuts-settings.jsx:162
#: src/pages/home.jsx:225
#: src/pages/mentions.jsx:21
#: src/pages/mentions.jsx:168
#: src/pages/settings.jsx:1216
#: src/pages/trending.jsx:382
msgid "Mentions"
msgstr ""

#: src/components/nav-menu.jsx:210
#: src/components/shortcuts-settings.jsx:48
#: src/components/shortcuts-settings.jsx:168
#: src/pages/filters.jsx:23
#: src/pages/home.jsx:85
#: src/pages/home.jsx:185
#: src/pages/notifications.jsx:113
#: src/pages/notifications.jsx:564
msgid "Notifications"
msgstr ""

#: src/components/nav-menu.jsx:213
msgid "New"
msgstr ""

#: src/components/nav-menu.jsx:224
#: src/components/shortcuts-settings.jsx:52
msgid "Profile"
msgstr ""

#: src/components/nav-menu.jsx:232
#: src/components/shortcuts-settings.jsx:53
#: src/components/shortcuts-settings.jsx:231
#: src/pages/bookmarks.jsx:12
#: src/pages/bookmarks.jsx:24
msgid "Bookmarks"
msgstr ""

#: src/components/nav-menu.jsx:252
#: src/components/shortcuts-settings.jsx:54
#: src/components/shortcuts-settings.jsx:237
#: src/pages/catchup.jsx:1446
#: src/pages/catchup.jsx:2064
#: src/pages/favourites.jsx:12
#: src/pages/favourites.jsx:24
#: src/pages/settings.jsx:1220
msgid "Likes"
msgstr ""

#: src/components/nav-menu.jsx:258
#: src/pages/followed-hashtags.jsx:15
#: src/pages/followed-hashtags.jsx:45
msgid "Followed Hashtags"
msgstr ""

#: src/components/nav-menu.jsx:266
#: src/pages/account-statuses.jsx:330
#: src/pages/filters.jsx:54
#: src/pages/filters.jsx:93
#: src/pages/hashtag.jsx:340
msgid "Filters"
msgstr ""

#: src/components/nav-menu.jsx:274
msgid "Muted users"
msgstr ""

#: src/components/nav-menu.jsx:282
msgid "Muted users…"
msgstr ""

#: src/components/nav-menu.jsx:289
msgid "Blocked users"
msgstr ""

#: src/components/nav-menu.jsx:297
msgid "Blocked users…"
msgstr ""

#: src/components/nav-menu.jsx:309
msgid "Accounts…"
msgstr ""

#: src/components/nav-menu.jsx:319
#: src/pages/login.jsx:190
#: src/pages/status.jsx:837
#: src/pages/welcome.jsx:65
msgid "Log in"
msgstr ""

#: src/components/nav-menu.jsx:336
#: src/components/shortcuts-settings.jsx:56
#: src/components/shortcuts-settings.jsx:205
#: src/pages/trending.jsx:442
msgid "Trending"
msgstr ""

#: src/components/nav-menu.jsx:349
#: src/components/shortcuts-settings.jsx:184
msgid "Bubble"
msgstr "Bubble"

#: src/components/nav-menu.jsx:356
#: src/components/shortcuts-settings.jsx:185
msgid "Federated"
msgstr ""

#: src/components/nav-menu.jsx:379
msgid "Shortcuts / Columns…"
msgstr ""

#: src/components/nav-menu.jsx:389
#: src/components/nav-menu.jsx:403
msgid "Settings…"
msgstr ""

#: src/components/nav-menu.jsx:433
#: src/components/nav-menu.jsx:460
#: src/components/shortcuts-settings.jsx:49
#: src/components/shortcuts-settings.jsx:174
#: src/pages/list.jsx:127
#: src/pages/lists.jsx:17
#: src/pages/lists.jsx:51
msgid "Lists"
msgstr ""

#: src/components/nav-menu.jsx:441
#: src/components/shortcuts.jsx:215
#: src/pages/list.jsx:134
msgid "All Lists"
msgstr ""

#: src/components/notification-service.jsx:161
msgid "Notification"
msgstr ""

#: src/components/notification-service.jsx:167
msgid "This notification is from your other account."
msgstr ""

#: src/components/notification-service.jsx:196
msgid "View all notifications"
msgstr ""

#: src/components/notification.jsx:72
msgid "{account} reacted to your post with {emojiObject}"
msgstr ""

#: src/components/notification.jsx:79
msgid "{account} published a post."
msgstr ""

#. placeholder {0}: shortenNumber(count)
#. placeholder {1}: shortenNumber(count)
#: src/components/notification.jsx:87
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted your reply.} other {{account} boosted your post.}}} other {{account} boosted {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted your reply.} other {<2><3>{1}</3> people</2> boosted your post.}}}}"
msgstr ""

#. placeholder {0}: shortenNumber(count)
#: src/components/notification.jsx:130
msgid "{count, plural, =1 {{account} followed you.} other {<0><1>{0}</1> people</0> followed you.}}"
msgstr ""

#: src/components/notification.jsx:144
msgid "{account} requested to follow you."
msgstr ""

#. placeholder {0}: shortenNumber(count)
#. placeholder {1}: shortenNumber(count)
#: src/components/notification.jsx:153
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} liked your reply.} other {{account} liked your post.}}} other {{account} liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> liked your reply.} other {<2><3>{1}</3> people</2> liked your post.}}}}"
msgstr ""

#: src/components/notification.jsx:195
msgid "A poll you have voted in or created has ended."
msgstr ""

#: src/components/notification.jsx:196
msgid "A poll you have created has ended."
msgstr ""

#: src/components/notification.jsx:197
msgid "A poll you have voted in has ended."
msgstr ""

#: src/components/notification.jsx:198
msgid "A post you interacted with has been edited."
msgstr ""

#. placeholder {0}: shortenNumber(count)
#. placeholder {1}: shortenNumber(count)
#: src/components/notification.jsx:206
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted & liked your reply.} other {{account} boosted & liked your post.}}} other {{account} boosted & liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted & liked your reply.} other {<2><3>{1}</3> people</2> boosted & liked your post.}}}}"
msgstr ""

#: src/components/notification.jsx:248
msgid "{account} signed up."
msgstr ""

#: src/components/notification.jsx:250
msgid "{account} reported {targetAccount}"
msgstr ""

#: src/components/notification.jsx:255
msgid "Lost connections with <0>{name}</0>."
msgstr ""

#: src/components/notification.jsx:261
msgid "Moderation warning"
msgstr ""

#: src/components/notification.jsx:266
msgid "Your {year} #Wrapstodon is here!"
msgstr "Your {year} #Wrapstodon is here!"

#: src/components/notification.jsx:269
msgid "{account} moved to {targetAccount}."
msgstr "{account} moved to {targetAccount}."

#: src/components/notification.jsx:279
msgid "An admin from <0>{from}</0> has suspended <1>{targetName}</1>, which means you can no longer receive updates from them or interact with them."
msgstr ""

#: src/components/notification.jsx:285
msgid "An admin from <0>{from}</0> has blocked <1>{targetName}</1>. Affected followers: {followersCount}, followings: {followingCount}."
msgstr ""

#: src/components/notification.jsx:291
msgid "You have blocked <0>{targetName}</0>. Removed followers: {followersCount}, followings: {followingCount}."
msgstr ""

#: src/components/notification.jsx:299
msgid "Your account has received a moderation warning."
msgstr ""

#: src/components/notification.jsx:300
msgid "Your account has been disabled."
msgstr ""

#: src/components/notification.jsx:301
msgid "Some of your posts have been marked as sensitive."
msgstr ""

#: src/components/notification.jsx:302
msgid "Some of your posts have been deleted."
msgstr ""

#: src/components/notification.jsx:303
msgid "Your posts will be marked as sensitive from now on."
msgstr ""

#: src/components/notification.jsx:304
msgid "Your account has been limited."
msgstr ""

#: src/components/notification.jsx:305
msgid "Your account has been suspended."
msgstr ""

#: src/components/notification.jsx:385
msgid "[Unknown notification type: {type}]"
msgstr ""

#: src/components/notification.jsx:459
#: src/components/status.jsx:1114
#: src/components/status.jsx:1124
msgid "Boosted/Liked by…"
msgstr ""

#: src/components/notification.jsx:460
msgid "Liked by…"
msgstr ""

#: src/components/notification.jsx:461
msgid "Boosted by…"
msgstr ""

#: src/components/notification.jsx:462
msgid "Followed by…"
msgstr ""

#: src/components/notification.jsx:533
#: src/components/notification.jsx:549
msgid "Learn more <0/>"
msgstr ""

#: src/components/notification.jsx:558
msgid "View #Wrapstodon"
msgstr "View #Wrapstodon"

#: src/components/notification.jsx:788
#: src/components/status.jsx:277
msgid "Read more →"
msgstr ""

#: src/components/poll.jsx:113
msgid "Voted"
msgstr ""

#: src/components/poll.jsx:119
msgid "{optionVotesCount, plural, one {# vote} other {# votes}}"
msgstr "{optionVotesCount, plural, one {# vote} other {# votes}}"

#: src/components/poll.jsx:139
#: src/components/poll.jsx:222
#: src/components/poll.jsx:226
msgid "Hide results"
msgstr ""

#: src/components/poll.jsx:188
msgid "Vote"
msgstr ""

#: src/components/poll.jsx:208
#: src/components/poll.jsx:210
#: src/pages/status.jsx:1203
#: src/pages/status.jsx:1226
msgid "Refresh"
msgstr ""

#: src/components/poll.jsx:222
#: src/components/poll.jsx:226
msgid "Show results"
msgstr ""

#. placeholder {0}: shortenNumber(votesCount)
#. placeholder {1}: shortenNumber(votesCount)
#: src/components/poll.jsx:231
msgid "{votesCount, plural, one {<0>{0}</0> vote} other {<1>{1}</1> votes}}"
msgstr ""

#. placeholder {0}: shortenNumber(votersCount)
#. placeholder {1}: shortenNumber(votersCount)
#: src/components/poll.jsx:248
msgid "{votersCount, plural, one {<0>{0}</0> voter} other {<1>{1}</1> voters}}"
msgstr ""

#: src/components/poll.jsx:268
msgid "Ended <0/>"
msgstr ""

#: src/components/poll.jsx:272
msgid "Ended"
msgstr ""

#: src/components/poll.jsx:275
msgid "Ending <0/>"
msgstr ""

#: src/components/poll.jsx:279
msgid "Ending"
msgstr ""

#. Relative time in seconds, as short as possible
#. placeholder {0}: seconds < 1 ? 1 : Math.floor(seconds)
#: src/components/relative-time.jsx:57
msgid "{0}s"
msgstr ""

#. Relative time in minutes, as short as possible
#. placeholder {0}: Math.floor(seconds / minute)
#: src/components/relative-time.jsx:62
msgid "{0}m"
msgstr ""

#. Relative time in hours, as short as possible
#. placeholder {0}: Math.floor(seconds / hour)
#: src/components/relative-time.jsx:67
msgid "{0}h"
msgstr ""

#: src/components/report-modal.jsx:29
msgid "Spam"
msgstr ""

#: src/components/report-modal.jsx:30
msgid "Malicious links, fake engagement, or repetitive replies"
msgstr ""

#: src/components/report-modal.jsx:33
msgid "Illegal"
msgstr ""

#: src/components/report-modal.jsx:34
msgid "Violates the law of your or the server's country"
msgstr ""

#: src/components/report-modal.jsx:37
msgid "Server rule violation"
msgstr ""

#: src/components/report-modal.jsx:38
msgid "Breaks specific server rules"
msgstr ""

#: src/components/report-modal.jsx:39
msgid "Violation"
msgstr ""

#: src/components/report-modal.jsx:42
msgid "Other"
msgstr ""

#: src/components/report-modal.jsx:43
msgid "Issue doesn't fit other categories"
msgstr ""

#: src/components/report-modal.jsx:68
msgid "Report Post"
msgstr ""

#: src/components/report-modal.jsx:68
msgid "Report @{username}"
msgstr ""

#: src/components/report-modal.jsx:104
msgid "Pending review"
msgstr ""

#: src/components/report-modal.jsx:146
msgid "Post reported"
msgstr ""

#: src/components/report-modal.jsx:146
msgid "Profile reported"
msgstr ""

#: src/components/report-modal.jsx:154
msgid "Unable to report post"
msgstr ""

#: src/components/report-modal.jsx:155
msgid "Unable to report profile"
msgstr ""

#: src/components/report-modal.jsx:163
msgid "What's the issue with this post?"
msgstr ""

#: src/components/report-modal.jsx:164
msgid "What's the issue with this profile?"
msgstr ""

#: src/components/report-modal.jsx:233
msgid "Additional info"
msgstr ""

#: src/components/report-modal.jsx:256
msgid "Forward to <0>{domain}</0>"
msgstr ""

#: src/components/report-modal.jsx:266
msgid "Send Report"
msgstr ""

#: src/components/report-modal.jsx:275
msgid "Muted {username}"
msgstr ""

#: src/components/report-modal.jsx:278
msgid "Unable to mute {username}"
msgstr ""

#: src/components/report-modal.jsx:283
msgid "Send Report <0>+ Mute profile</0>"
msgstr ""

#: src/components/report-modal.jsx:294
msgid "Blocked {username}"
msgstr ""

#: src/components/report-modal.jsx:297
msgid "Unable to block {username}"
msgstr ""

#: src/components/report-modal.jsx:302
msgid "Send Report <0>+ Block profile</0>"
msgstr ""

#: src/components/search-form.jsx:203
msgid "Look up <0>{query}</0>"
msgstr ""

#: src/components/search-form.jsx:214
msgid "{query} <0>‒ accounts, hashtags & posts</0>"
msgstr ""

#: src/components/search-form.jsx:227
msgid "Posts with <0>{query}</0>"
msgstr ""

#. placeholder {0}: query.replace(/^#/, '')
#: src/components/search-form.jsx:239
msgid "Posts tagged with <0>#{0}</0>"
msgstr ""

#: src/components/search-form.jsx:253
msgid "Accounts with <0>{query}</0>"
msgstr ""

#: src/components/shortcuts-settings.jsx:47
msgid "Home / Following"
msgstr ""

#: src/components/shortcuts-settings.jsx:50
msgid "Local / Bubble / Federated"
msgstr "Local / Bubble / Federated"

#: src/components/shortcuts-settings.jsx:55
msgid "Hashtag"
msgstr ""

#: src/components/shortcuts-settings.jsx:62
msgid "List ID"
msgstr ""

#: src/components/shortcuts-settings.jsx:69
msgid "Variant"
msgstr "Variant"

#: src/components/shortcuts-settings.jsx:72
msgid "'local', 'bubble' or 'federated'"
msgstr "'local', 'bubble' or 'federated'"

#: src/components/shortcuts-settings.jsx:75
#: src/components/shortcuts-settings.jsx:84
#: src/components/shortcuts-settings.jsx:138
#: src/pages/login.jsx:194
msgid "Instance"
msgstr ""

#: src/components/shortcuts-settings.jsx:78
#: src/components/shortcuts-settings.jsx:87
#: src/components/shortcuts-settings.jsx:141
msgid "Optional, e.g. mastodon.social"
msgstr ""

#: src/components/shortcuts-settings.jsx:93
msgid "Search term"
msgstr ""

#: src/components/shortcuts-settings.jsx:96
msgid "Optional, unless for multi-column mode"
msgstr ""

#: src/components/shortcuts-settings.jsx:108
msgid "− Replies"
msgstr "− Replies"

#: src/components/shortcuts-settings.jsx:113
msgid "− Boosts"
msgstr "− Boosts"

#: src/components/shortcuts-settings.jsx:118
#: src/components/shortcuts-settings.jsx:133
#: src/pages/hashtag.jsx:356
msgid "Media only"
msgstr ""

#: src/components/shortcuts-settings.jsx:129
msgid "e.g. PixelArt (Max 5, space-separated)"
msgstr ""

#: src/components/shortcuts-settings.jsx:271
#: src/components/shortcuts.jsx:192
msgid "Shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:279
msgid "beta"
msgstr ""

#: src/components/shortcuts-settings.jsx:285
msgid "Specify a list of shortcuts that'll appear as:"
msgstr ""

#: src/components/shortcuts-settings.jsx:291
msgid "Floating button"
msgstr ""

#: src/components/shortcuts-settings.jsx:296
msgid "Tab/Menu bar"
msgstr ""

#: src/components/shortcuts-settings.jsx:301
msgid "Multi-column"
msgstr ""

#: src/components/shortcuts-settings.jsx:368
msgid "Not available in current view mode"
msgstr ""

#: src/components/shortcuts-settings.jsx:387
msgid "Move up"
msgstr ""

#: src/components/shortcuts-settings.jsx:403
msgid "Move down"
msgstr ""

#: src/components/shortcuts-settings.jsx:415
#: src/components/status.jsx:1372
#: src/pages/list.jsx:171
msgid "Edit"
msgstr ""

#: src/components/shortcuts-settings.jsx:436
msgid "Add more than one shortcut/column to make this work."
msgstr ""

#: src/components/shortcuts-settings.jsx:447
msgid "No columns yet. Tap on the Add column button."
msgstr ""

#: src/components/shortcuts-settings.jsx:448
msgid "No shortcuts yet. Tap on the Add shortcut button."
msgstr ""

#: src/components/shortcuts-settings.jsx:451
msgid "Not sure what to add?<0/>Try adding <1>Home / Following and Notifications</1> first."
msgstr ""

#: src/components/shortcuts-settings.jsx:479
msgid "Max {SHORTCUTS_LIMIT} columns"
msgstr ""

#: src/components/shortcuts-settings.jsx:480
msgid "Max {SHORTCUTS_LIMIT} shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:494
msgid "Import/export"
msgstr ""

#: src/components/shortcuts-settings.jsx:504
msgid "Add column…"
msgstr ""

#: src/components/shortcuts-settings.jsx:505
msgid "Add shortcut…"
msgstr ""

#: src/components/shortcuts-settings.jsx:552
msgid "Specific list is optional. For multi-column mode, list is required, else the column will not be shown."
msgstr ""

#: src/components/shortcuts-settings.jsx:553
msgid "For multi-column mode, search term is required, else the column will not be shown."
msgstr ""

#: src/components/shortcuts-settings.jsx:554
msgid "Multiple hashtags are supported. Space-separated."
msgstr ""

#: src/components/shortcuts-settings.jsx:623
msgid "Edit shortcut"
msgstr ""

#: src/components/shortcuts-settings.jsx:623
msgid "Add shortcut"
msgstr ""

#: src/components/shortcuts-settings.jsx:659
msgid "Timeline"
msgstr ""

#: src/components/shortcuts-settings.jsx:685
msgid "List"
msgstr ""

#: src/components/shortcuts-settings.jsx:824
msgid "Import/Export <0>Shortcuts</0>"
msgstr ""

#: src/components/shortcuts-settings.jsx:834
msgid "Import"
msgstr ""

#: src/components/shortcuts-settings.jsx:842
msgid "Paste shortcuts here"
msgstr ""

#: src/components/shortcuts-settings.jsx:858
msgid "Downloading saved shortcuts from instance server…"
msgstr ""

#: src/components/shortcuts-settings.jsx:887
msgid "Unable to download shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:890
msgid "Download shortcuts from instance server"
msgstr ""

#: src/components/shortcuts-settings.jsx:948
msgid "* Exists in current shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:953
msgid "List may not work if it's from a different account."
msgstr ""

#: src/components/shortcuts-settings.jsx:963
msgid "Invalid settings format"
msgstr ""

#: src/components/shortcuts-settings.jsx:971
msgid "Append to current shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:974
msgid "Only shortcuts that don’t exist in current shortcuts will be appended."
msgstr ""

#: src/components/shortcuts-settings.jsx:996
msgid "No new shortcuts to import"
msgstr ""

#: src/components/shortcuts-settings.jsx:1011
msgid "Shortcuts imported. Exceeded max {SHORTCUTS_LIMIT}, so the rest are not imported."
msgstr ""

#: src/components/shortcuts-settings.jsx:1012
#: src/components/shortcuts-settings.jsx:1036
msgid "Shortcuts imported"
msgstr ""

#: src/components/shortcuts-settings.jsx:1022
msgid "Import & append…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1030
msgid "Override current shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:1031
msgid "Import shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:1045
msgid "or override…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1045
msgid "Import…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1054
msgid "Export"
msgstr ""

#: src/components/shortcuts-settings.jsx:1069
msgid "Shortcuts copied"
msgstr ""

#: src/components/shortcuts-settings.jsx:1072
msgid "Unable to copy shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:1086
msgid "Shortcut settings copied"
msgstr ""

#: src/components/shortcuts-settings.jsx:1089
msgid "Unable to copy shortcut settings"
msgstr ""

#: src/components/shortcuts-settings.jsx:1095
#: src/components/status.jsx:3268
msgid "Copy"
msgstr ""

#: src/components/shortcuts-settings.jsx:1113
msgid "Sharing doesn't seem to work."
msgstr ""

#: src/components/shortcuts-settings.jsx:1119
msgid "Share"
msgstr ""

#: src/components/shortcuts-settings.jsx:1158
msgid "Saving shortcuts to instance server…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1165
msgid "Shortcuts saved"
msgstr ""

#: src/components/shortcuts-settings.jsx:1170
msgid "Unable to save shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:1173
msgid "Sync to instance server"
msgstr ""

#. placeholder {0}: shortcutsStr.length
#: src/components/shortcuts-settings.jsx:1181
msgid "{0, plural, one {# character} other {# characters}}"
msgstr ""

#: src/components/shortcuts-settings.jsx:1193
msgid "Raw Shortcuts JSON"
msgstr ""

#: src/components/shortcuts-settings.jsx:1206
msgid "Import/export settings from/to instance server (Very experimental)"
msgstr ""

#: src/components/status.jsx:97
msgid "Restricted"
msgstr "Restricted"

#: src/components/status.jsx:102
msgid "P"
msgstr "P"

#: src/components/status.jsx:103
msgid "L"
msgstr "L"

#: src/components/status.jsx:104
msgid "U"
msgstr "U"

#: src/components/status.jsx:105
msgid "F"
msgstr "F"

#: src/components/status.jsx:106
msgid "R"
msgstr "R"

#: src/components/status.jsx:107
msgid "DM"
msgstr "DM"

#: src/components/status.jsx:497
msgid "Thread muted"
msgstr "Thread muted"

#: src/components/status.jsx:586
msgid "<0>Posted in</0> <1/> "
msgstr "<0>Posted in</0> <1/> "

#: src/components/status.jsx:616
msgid "<0/> <1>boosted</1>"
msgstr ""

#: src/components/status.jsx:715
msgid "Sorry, your current logged-in instance can't interact with this post from another instance."
msgstr ""

#. placeholder {0}: username || acct
#: src/components/status.jsx:868
msgid "Unliked @{0}'s post"
msgstr ""

#. placeholder {1}: username || acct
#: src/components/status.jsx:869
msgid "Liked @{1}'s post"
msgstr "Liked @{1}'s post"

#. placeholder {2}: username || acct
#: src/components/status.jsx:908
msgid "Unbookmarked @{2}'s post"
msgstr "Unbookmarked @{2}'s post"

#. placeholder {3}: username || acct
#: src/components/status.jsx:909
msgid "Bookmarked @{3}'s post"
msgstr "Bookmarked @{3}'s post"

#: src/components/status.jsx:1015
#: src/components/status.jsx:1077
#: src/components/status.jsx:2412
#: src/components/status.jsx:2443
msgid "Unboost"
msgstr ""

#: src/components/status.jsx:1031
#: src/components/status.jsx:2427
msgid "Quote"
msgstr ""

#: src/components/status.jsx:1039
#: src/components/status.jsx:2436
msgid "Some media have no descriptions."
msgstr ""

#. placeholder {0}: rtf.format(-statusMonthsAgo, 'month')
#: src/components/status.jsx:1046
msgid "Old post (<0>{0}</0>)"
msgstr ""

#. placeholder {4}: username || acct
#: src/components/status.jsx:1065
msgid "Unboosted @{4}'s post"
msgstr "Unboosted @{4}'s post"

#. placeholder {5}: username || acct
#: src/components/status.jsx:1066
msgid "Boosted @{5}'s post"
msgstr "Boosted @{5}'s post"

#: src/components/status.jsx:1078
msgid "Boost…"
msgstr ""

#: src/components/status.jsx:1090
#: src/components/status.jsx:1785
#: src/components/status.jsx:2456
msgid "Unlike"
msgstr ""

#: src/components/status.jsx:1091
#: src/components/status.jsx:1785
#: src/components/status.jsx:1786
#: src/components/status.jsx:2456
#: src/components/status.jsx:2457
msgid "Like"
msgstr ""

#: src/components/status.jsx:1100
#: src/components/status.jsx:2468
msgid "Unbookmark"
msgstr ""

#: src/components/status.jsx:1207
msgid "Full view"
msgstr "Full view"

#: src/components/status.jsx:1255
#: src/components/status.jsx:3237
msgid "Embed post"
msgstr ""

#: src/components/status.jsx:1274
msgid "Show Edit History"
msgstr ""

#: src/components/status.jsx:1277
msgid "Edited: {editedDateText}"
msgstr ""

#: src/components/status.jsx:1293
msgid "Conversation unmuted"
msgstr ""

#: src/components/status.jsx:1293
msgid "Conversation muted"
msgstr ""

#: src/components/status.jsx:1299
msgid "Unable to unmute conversation"
msgstr ""

#: src/components/status.jsx:1300
msgid "Unable to mute conversation"
msgstr ""

#: src/components/status.jsx:1309
msgid "Unmute conversation"
msgstr ""

#: src/components/status.jsx:1316
msgid "Mute conversation"
msgstr ""

#: src/components/status.jsx:1332
msgid "Post unpinned from profile"
msgstr ""

#: src/components/status.jsx:1333
msgid "Post pinned to profile"
msgstr ""

#: src/components/status.jsx:1338
msgid "Unable to unpin post"
msgstr ""

#: src/components/status.jsx:1338
msgid "Unable to pin post"
msgstr ""

#: src/components/status.jsx:1347
msgid "Unpin from profile"
msgstr ""

#: src/components/status.jsx:1354
msgid "Pin to profile"
msgstr ""

#: src/components/status.jsx:1382
msgid "Delete this post?"
msgstr ""

#: src/components/status.jsx:1398
msgid "Post deleted"
msgstr ""

#: src/components/status.jsx:1401
msgid "Unable to delete post"
msgstr ""

#: src/components/status.jsx:1427
msgid "Report post…"
msgstr ""

#. placeholder {6}: username || acct
#: src/components/status.jsx:1500
msgid "Unboosted @{6}'s post"
msgstr "Unboosted @{6}'s post"

#. placeholder {7}: username || acct
#: src/components/status.jsx:1501
msgid "Boosted @{7}'s post"
msgstr "Boosted @{7}'s post"

#: src/components/status.jsx:1786
#: src/components/status.jsx:1822
#: src/components/status.jsx:2457
msgid "Liked"
msgstr ""

#: src/components/status.jsx:1819
#: src/components/status.jsx:2444
msgid "Boosted"
msgstr ""

#: src/components/status.jsx:1829
#: src/components/status.jsx:2469
msgid "Bookmarked"
msgstr ""

#: src/components/status.jsx:1833
msgid "Pinned"
msgstr ""

#: src/components/status.jsx:1878
#: src/components/status.jsx:2266
msgid "Deleted"
msgstr ""

#: src/components/status.jsx:1923
msgid "{repliesCount, plural, one {# reply} other {# replies}}"
msgstr ""

#. placeholder {0}: snapStates.statusThreadNumber[sKey] ? ` ${snapStates.statusThreadNumber[sKey]}` : ''
#: src/components/status.jsx:1945
msgid "{0}"
msgstr "{0}"

#: src/components/status.jsx:2015
#: src/components/status.jsx:2074
#: src/components/status.jsx:2163
msgid "Show less"
msgstr ""

#: src/components/status.jsx:2017
#: src/components/status.jsx:2076
msgid "Show content"
msgstr ""

#: src/components/status.jsx:2163
msgid "Show media"
msgstr ""

#: src/components/status.jsx:2304
msgid "Edited"
msgstr ""

#: src/components/status.jsx:2386
msgid "Comments"
msgstr ""

#. More from [Author]
#: src/components/status.jsx:2695
msgid "More from <0/>"
msgstr "More from <0/>"

#: src/components/status.jsx:2997
msgid "Edit History"
msgstr ""

#: src/components/status.jsx:3001
msgid "Failed to load history"
msgstr ""

#: src/components/status.jsx:3006
#: src/pages/annual-report.jsx:45
msgid "Loading…"
msgstr ""

#: src/components/status.jsx:3242
msgid "HTML Code"
msgstr ""

#: src/components/status.jsx:3259
msgid "HTML code copied"
msgstr ""

#: src/components/status.jsx:3262
msgid "Unable to copy HTML code"
msgstr ""

#: src/components/status.jsx:3274
msgid "Media attachments:"
msgstr ""

#: src/components/status.jsx:3296
msgid "Account Emojis:"
msgstr ""

#: src/components/status.jsx:3327
#: src/components/status.jsx:3372
msgid "static URL"
msgstr ""

#: src/components/status.jsx:3341
msgid "Emojis:"
msgstr ""

#: src/components/status.jsx:3386
msgid "Notes:"
msgstr ""

#: src/components/status.jsx:3390
msgid "This is static, unstyled and scriptless. You may need to apply your own styles and edit as needed."
msgstr ""

#: src/components/status.jsx:3396
msgid "Polls are not interactive, becomes a list with vote counts."
msgstr ""

#: src/components/status.jsx:3401
msgid "Media attachments can be images, videos, audios or any file types."
msgstr ""

#: src/components/status.jsx:3407
msgid "Post could be edited or deleted later."
msgstr ""

#: src/components/status.jsx:3413
msgid "Preview"
msgstr ""

#: src/components/status.jsx:3422
msgid "Note: This preview is lightly styled."
msgstr ""

#. [Name] [Visibility icon] boosted
#: src/components/status.jsx:3670
msgid "<0/> <1/> boosted"
msgstr ""

#: src/components/timeline.jsx:455
#: src/pages/settings.jsx:1244
msgid "New posts"
msgstr ""

#: src/components/timeline.jsx:556
#: src/pages/home.jsx:214
#: src/pages/notifications.jsx:871
#: src/pages/status.jsx:990
#: src/pages/status.jsx:1363
msgid "Try again"
msgstr ""

#. placeholder {0}: fItems.length
#: src/components/timeline.jsx:591
msgid "{0, plural, one {# Boost} other {# Boosts}}"
msgstr "{0, plural, one {# Boost} other {# Boosts}}"

#: src/components/timeline.jsx:596
msgid "Pinned posts"
msgstr "Pinned posts"

#: src/components/timeline.jsx:949
#: src/components/timeline.jsx:956
#: src/pages/catchup.jsx:1894
msgid "Thread"
msgstr ""

#. placeholder {0}: filterInfo.titlesStr
#: src/components/timeline.jsx:972
msgid "<0>{0}</0>"
msgstr "<0>{0}</0>"

#: src/components/translation-block.jsx:153
msgid "Auto-translated from {sourceLangText}"
msgstr ""

#: src/components/translation-block.jsx:191
msgid "Translating…"
msgstr ""

#: src/components/translation-block.jsx:194
msgid "Translate from {sourceLangText} (auto-detected)"
msgstr ""

#: src/components/translation-block.jsx:195
msgid "Translate from {sourceLangText}"
msgstr ""

#. placeholder {0}: detectedLang ?? '…'
#: src/components/translation-block.jsx:223
msgid "Auto ({0})"
msgstr ""

#: src/components/translation-block.jsx:236
msgid "Failed to translate"
msgstr ""

#: src/compose.jsx:33
msgid "Editing source status"
msgstr ""

#. placeholder {0}: replyToStatus.account?.acct || replyToStatus.account?.username
#: src/compose.jsx:35
msgid "Replying to @{0}"
msgstr ""

#: src/compose.jsx:63
msgid "You may close this page now."
msgstr ""

#: src/compose.jsx:71
msgid "Close window"
msgstr ""

#: src/compose.jsx:87
msgid "Login required."
msgstr "Login required."

#: src/compose.jsx:91
#: src/pages/annual-report.jsx:164
#: src/pages/http-route.jsx:91
#: src/pages/login.jsx:271
msgid "Go home"
msgstr ""

#: src/pages/account-statuses.jsx:232
msgid "Account posts"
msgstr ""

#: src/pages/account-statuses.jsx:239
msgid "{accountDisplay} (+ Replies)"
msgstr ""

#: src/pages/account-statuses.jsx:241
msgid "{accountDisplay} (- Boosts)"
msgstr ""

#: src/pages/account-statuses.jsx:243
msgid "{accountDisplay} (#{tagged})"
msgstr ""

#: src/pages/account-statuses.jsx:245
msgid "{accountDisplay} (Media)"
msgstr ""

#: src/pages/account-statuses.jsx:251
msgid "{accountDisplay} ({monthYear})"
msgstr ""

#: src/pages/account-statuses.jsx:320
msgid "Clear filters"
msgstr ""

#: src/pages/account-statuses.jsx:323
msgid "Clear"
msgstr ""

#: src/pages/account-statuses.jsx:337
msgid "Showing post with replies"
msgstr ""

#: src/pages/account-statuses.jsx:342
msgid "+ Replies"
msgstr ""

#: src/pages/account-statuses.jsx:348
msgid "Showing posts without boosts"
msgstr ""

#: src/pages/account-statuses.jsx:353
msgid "- Boosts"
msgstr ""

#: src/pages/account-statuses.jsx:359
msgid "Showing posts with media"
msgstr ""

#. placeholder {0}: tag.name
#: src/pages/account-statuses.jsx:376
msgid "Showing posts tagged with #{0}"
msgstr ""

#. placeholder {1}: date.toLocaleString(i18n.locale, { month: 'long', year: 'numeric', })
#: src/pages/account-statuses.jsx:415
msgid "Showing posts in {1}"
msgstr "Showing posts in {1}"

#: src/pages/account-statuses.jsx:504
msgid "Nothing to see here yet."
msgstr ""

#: src/pages/account-statuses.jsx:505
#: src/pages/public.jsx:116
#: src/pages/trending.jsx:450
msgid "Unable to load posts"
msgstr ""

#: src/pages/account-statuses.jsx:546
#: src/pages/account-statuses.jsx:576
msgid "Unable to fetch account info"
msgstr ""

#. placeholder {0}: accountInstance ? ( <> {' '} (<b>{punycode.toUnicode(accountInstance)}</b>) </> ) : null
#: src/pages/account-statuses.jsx:553
msgid "Switch to account's instance {0}"
msgstr ""

#: src/pages/account-statuses.jsx:583
msgid "Switch to my instance (<0>{currentInstance}</0>)"
msgstr ""

#: src/pages/account-statuses.jsx:645
msgid "Month"
msgstr ""

#: src/pages/accounts.jsx:56
msgid "Current"
msgstr ""

#: src/pages/accounts.jsx:102
msgid "Default"
msgstr ""

#: src/pages/accounts.jsx:124
msgid "Switch to this account"
msgstr "Switch to this account"

#: src/pages/accounts.jsx:133
msgid "Switch in new tab/window"
msgstr "Switch in new tab/window"

#: src/pages/accounts.jsx:147
msgid "View profile…"
msgstr ""

#: src/pages/accounts.jsx:164
msgid "Set as default"
msgstr ""

#. placeholder {0}: account.info.acct
#: src/pages/accounts.jsx:174
msgid "Log out <0>@{0}</0>?"
msgstr ""

#: src/pages/accounts.jsx:197
msgid "Log out…"
msgstr ""

#: src/pages/accounts.jsx:210
msgid "Add an existing account"
msgstr ""

#: src/pages/accounts.jsx:217
msgid "Note: <0>Default</0> account will always be used for first load. Switched accounts will persist during the session."
msgstr ""

#: src/pages/bookmarks.jsx:26
msgid "No bookmarks yet. Go bookmark something!"
msgstr "No bookmarks yet. Go bookmark something!"

#: src/pages/bookmarks.jsx:27
msgid "Unable to load bookmarks."
msgstr ""

#: src/pages/catchup.jsx:54
msgid "last 1 hour"
msgstr ""

#: src/pages/catchup.jsx:55
msgid "last 2 hours"
msgstr ""

#: src/pages/catchup.jsx:56
msgid "last 3 hours"
msgstr ""

#: src/pages/catchup.jsx:57
msgid "last 4 hours"
msgstr ""

#: src/pages/catchup.jsx:58
msgid "last 5 hours"
msgstr ""

#: src/pages/catchup.jsx:59
msgid "last 6 hours"
msgstr ""

#: src/pages/catchup.jsx:60
msgid "last 7 hours"
msgstr ""

#: src/pages/catchup.jsx:61
msgid "last 8 hours"
msgstr ""

#: src/pages/catchup.jsx:62
msgid "last 9 hours"
msgstr ""

#: src/pages/catchup.jsx:63
msgid "last 10 hours"
msgstr ""

#: src/pages/catchup.jsx:64
msgid "last 11 hours"
msgstr ""

#: src/pages/catchup.jsx:65
msgid "last 12 hours"
msgstr ""

#: src/pages/catchup.jsx:66
msgid "beyond 12 hours"
msgstr ""

#: src/pages/catchup.jsx:73
msgid "Followed tags"
msgstr ""

#: src/pages/catchup.jsx:74
msgid "Groups"
msgstr ""

#: src/pages/catchup.jsx:596
msgid "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"
msgstr "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"

#: src/pages/catchup.jsx:882
#: src/pages/catchup.jsx:906
msgid "Catch-up <0>beta</0>"
msgstr ""

#: src/pages/catchup.jsx:896
#: src/pages/catchup.jsx:1585
msgid "Help"
msgstr ""

#: src/pages/catchup.jsx:912
msgid "What is this?"
msgstr ""

#: src/pages/catchup.jsx:915
msgid "Catch-up is a separate timeline for your followings, offering a high-level view at a glance, with a simple, email-inspired interface to effortlessly sort and filter through posts."
msgstr ""

#: src/pages/catchup.jsx:926
msgid "Preview of Catch-up UI"
msgstr "Preview of Catch-up UI"

#: src/pages/catchup.jsx:935
msgid "Let's catch up"
msgstr ""

#: src/pages/catchup.jsx:940
msgid "Let's catch up on the posts from your followings."
msgstr ""

#: src/pages/catchup.jsx:944
msgid "Show me all posts from…"
msgstr ""

#: src/pages/catchup.jsx:967
msgid "until the max"
msgstr "until the max"

#: src/pages/catchup.jsx:997
msgid "Catch up"
msgstr ""

#: src/pages/catchup.jsx:1003
msgid "Overlaps with your last catch-up"
msgstr ""

#. placeholder {0}: dtf.format(new Date(lastCatchupEndAt))
#: src/pages/catchup.jsx:1015
msgid "Until the last catch-up ({0})"
msgstr ""

#: src/pages/catchup.jsx:1024
msgid "Note: your instance might only show a maximum of 800 posts in the Home timeline regardless of the time range. Could be less or more."
msgstr ""

#: src/pages/catchup.jsx:1034
msgid "Previously…"
msgstr ""

#. placeholder {0}: pc.count
#: src/pages/catchup.jsx:1052
msgid "{0, plural, one {# post} other {# posts}}"
msgstr ""

#: src/pages/catchup.jsx:1062
msgid "Remove this catch-up?"
msgstr "Remove this catch-up?"

#. placeholder {0}: pc.id
#: src/pages/catchup.jsx:1065
msgid "Removing Catch-up {0}"
msgstr "Removing Catch-up {0}"

#. placeholder {1}: pc.id
#: src/pages/catchup.jsx:1069
msgid "Catch-up {1} removed"
msgstr "Catch-up {1} removed"

#: src/pages/catchup.jsx:1083
msgid "Note: Only max 3 will be stored. The rest will be automatically removed."
msgstr ""

#: src/pages/catchup.jsx:1098
msgid "Fetching posts…"
msgstr ""

#: src/pages/catchup.jsx:1101
msgid "This might take a while."
msgstr ""

#: src/pages/catchup.jsx:1136
msgid "Reset filters"
msgstr ""

#: src/pages/catchup.jsx:1144
#: src/pages/catchup.jsx:1591
msgid "Top links"
msgstr ""

#. placeholder {0}: sharers.map((s) => { const { avatarStatic, displayName } = s; return ( <button type="button" class="plain" style={{ padding: 0, }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); // Reset and filter to author const { id } = s; setSelectedAuthor(id); setSelectedFilterCategory('all'); }} > <Avatar url={avatarStatic} size="s" alt={displayName} /> </button> ); })
#: src/pages/catchup.jsx:1261
msgid "Shared by {0}"
msgstr ""

#: src/pages/catchup.jsx:1316
#: src/pages/mentions.jsx:148
#: src/pages/search.jsx:313
msgid "All"
msgstr ""

#. placeholder {0}: authorCountsList.length
#: src/pages/catchup.jsx:1401
msgid "{0, plural, one {# author} other {# authors}}"
msgstr ""

#: src/pages/catchup.jsx:1413
msgid "Sort"
msgstr ""

#: src/pages/catchup.jsx:1444
msgid "Date"
msgstr "Date"

#: src/pages/catchup.jsx:1448
msgid "Density"
msgstr "Density"

#. js-lingui-explicit-id
#: src/pages/catchup.jsx:1471
msgid "group.filter"
msgstr "Group"

#: src/pages/catchup.jsx:1486
msgid "Authors"
msgstr "Authors"

#: src/pages/catchup.jsx:1487
msgid "None"
msgstr "None"

#: src/pages/catchup.jsx:1503
msgid "Show all authors"
msgstr ""

#: src/pages/catchup.jsx:1554
msgid "You don't have to read everything."
msgstr "You don't have to read everything."

#: src/pages/catchup.jsx:1555
msgid "That's all."
msgstr "That's all."

#: src/pages/catchup.jsx:1563
msgid "Back to top"
msgstr ""

#: src/pages/catchup.jsx:1594
msgid "Links shared by followings, sorted by shared counts, boosts and likes."
msgstr ""

#: src/pages/catchup.jsx:1600
msgid "Sort: Density"
msgstr ""

#: src/pages/catchup.jsx:1603
msgid "Posts are sorted by information density or depth. Shorter posts are \"lighter\" while longer posts are \"heavier\". Posts with photos are \"heavier\" than posts without photos."
msgstr ""

#: src/pages/catchup.jsx:1610
msgid "Group: Authors"
msgstr ""

#: src/pages/catchup.jsx:1613
msgid "Posts are grouped by authors, sorted by posts count per author."
msgstr ""

#: src/pages/catchup.jsx:1660
msgid "Next author"
msgstr ""

#: src/pages/catchup.jsx:1668
msgid "Previous author"
msgstr ""

#: src/pages/catchup.jsx:1684
msgid "Scroll to top"
msgstr ""

#. placeholder {0}: filterInfo.titlesStr
#: src/pages/catchup.jsx:1876
msgid "Filtered: {0}"
msgstr "Filtered: {0}"

#: src/pages/favourites.jsx:26
msgid "No likes yet. Go like something!"
msgstr "No likes yet. Go like something!"

#: src/pages/favourites.jsx:27
msgid "Unable to load likes."
msgstr ""

#: src/pages/filters.jsx:22
msgid "Home and lists"
msgstr ""

#: src/pages/filters.jsx:24
msgid "Public timelines"
msgstr ""

#: src/pages/filters.jsx:25
msgid "Conversations"
msgstr ""

#: src/pages/filters.jsx:26
msgid "Profiles"
msgstr ""

#: src/pages/filters.jsx:41
msgid "Never"
msgstr ""

#: src/pages/filters.jsx:103
#: src/pages/filters.jsx:228
msgid "New filter"
msgstr ""

#. placeholder {0}: filters.length
#: src/pages/filters.jsx:151
msgid "{0, plural, one {# filter} other {# filters}}"
msgstr ""

#: src/pages/filters.jsx:166
msgid "Unable to load filters."
msgstr ""

#: src/pages/filters.jsx:170
msgid "No filters yet."
msgstr ""

#: src/pages/filters.jsx:177
msgid "Add filter"
msgstr ""

#: src/pages/filters.jsx:228
msgid "Edit filter"
msgstr ""

#: src/pages/filters.jsx:345
msgid "Unable to edit filter"
msgstr ""

#: src/pages/filters.jsx:346
msgid "Unable to create filter"
msgstr ""

#: src/pages/filters.jsx:355
msgid "Title"
msgstr ""

#: src/pages/filters.jsx:396
msgid "Whole word"
msgstr ""

#: src/pages/filters.jsx:422
msgid "No keywords. Add one."
msgstr ""

#: src/pages/filters.jsx:449
msgid "Add keyword"
msgstr ""

#. placeholder {0}: filteredEditKeywords.length
#: src/pages/filters.jsx:453
msgid "{0, plural, one {# keyword} other {# keywords}}"
msgstr ""

#: src/pages/filters.jsx:466
msgid "Filter from…"
msgstr ""

#: src/pages/filters.jsx:492
msgid "* Not implemented yet"
msgstr ""

#: src/pages/filters.jsx:498
msgid "Status: <0><1/></0>"
msgstr ""

#: src/pages/filters.jsx:507
msgid "Change expiry"
msgstr ""

#: src/pages/filters.jsx:507
msgid "Expiry"
msgstr ""

#: src/pages/filters.jsx:526
msgid "Filtered post will be…"
msgstr ""

#: src/pages/filters.jsx:536
msgid "minimized"
msgstr ""

#: src/pages/filters.jsx:546
msgid "hidden"
msgstr ""

#: src/pages/filters.jsx:563
msgid "Delete this filter?"
msgstr ""

#: src/pages/filters.jsx:576
msgid "Unable to delete filter."
msgstr ""

#: src/pages/filters.jsx:609
msgid "Expired"
msgstr ""

#: src/pages/filters.jsx:611
msgid "Expiring <0/>"
msgstr ""

#: src/pages/filters.jsx:615
msgid "Never expires"
msgstr ""

#. placeholder {0}: followedHashtags.length
#: src/pages/followed-hashtags.jsx:71
msgid "{0, plural, one {# hashtag} other {# hashtags}}"
msgstr ""

#: src/pages/followed-hashtags.jsx:86
msgid "Unable to load followed hashtags."
msgstr ""

#: src/pages/followed-hashtags.jsx:90
msgid "No hashtags followed yet."
msgstr ""

#: src/pages/following.jsx:144
msgid "Nothing to see here."
msgstr ""

#: src/pages/following.jsx:145
#: src/pages/list.jsx:109
msgid "Unable to load posts."
msgstr ""

#: src/pages/hashtag.jsx:56
msgid "{hashtagTitle} (Media only) on {instance}"
msgstr ""

#: src/pages/hashtag.jsx:57
msgid "{hashtagTitle} on {instance}"
msgstr ""

#: src/pages/hashtag.jsx:59
msgid "{hashtagTitle} (Media only)"
msgstr ""

#: src/pages/hashtag.jsx:60
msgid "{hashtagTitle}"
msgstr ""

#: src/pages/hashtag.jsx:182
msgid "No one has posted anything with this tag yet."
msgstr ""

#: src/pages/hashtag.jsx:183
msgid "Unable to load posts with this tag"
msgstr ""

#: src/pages/hashtag.jsx:209
msgid "Unfollow #{hashtag}?"
msgstr "Unfollow #{hashtag}?"

#: src/pages/hashtag.jsx:224
msgid "Unfollowed #{hashtag}"
msgstr ""

#: src/pages/hashtag.jsx:239
msgid "Followed #{hashtag}"
msgstr ""

#: src/pages/hashtag.jsx:255
msgid "Following…"
msgstr ""

#: src/pages/hashtag.jsx:283
msgid "Unfeatured on profile"
msgstr ""

#: src/pages/hashtag.jsx:297
msgid "Unable to unfeature on profile"
msgstr ""

#: src/pages/hashtag.jsx:306
#: src/pages/hashtag.jsx:322
msgid "Featured on profile"
msgstr ""

#: src/pages/hashtag.jsx:329
msgid "Feature on profile"
msgstr ""

#: src/pages/hashtag.jsx:394
msgid "{TOTAL_TAGS_LIMIT, plural, other {Max # tags}}"
msgstr ""

#: src/pages/hashtag.jsx:397
msgid "Add hashtag"
msgstr ""

#: src/pages/hashtag.jsx:429
msgid "Remove hashtag"
msgstr ""

#: src/pages/hashtag.jsx:443
msgid "{SHORTCUTS_LIMIT, plural, one {Max # shortcut reached. Unable to add shortcut.} other {Max # shortcuts reached. Unable to add shortcut.}}"
msgstr ""

#: src/pages/hashtag.jsx:472
msgid "This shortcut already exists"
msgstr ""

#: src/pages/hashtag.jsx:475
msgid "Hashtag shortcut added"
msgstr ""

#: src/pages/hashtag.jsx:481
msgid "Add to Shortcuts"
msgstr ""

#: src/pages/hashtag.jsx:487
#: src/pages/public.jsx:165
#: src/pages/trending.jsx:479
msgid "Enter a new instance e.g. \"mastodon.social\""
msgstr ""

#: src/pages/hashtag.jsx:490
#: src/pages/public.jsx:168
#: src/pages/trending.jsx:482
msgid "Invalid instance"
msgstr ""

#: src/pages/hashtag.jsx:504
#: src/pages/public.jsx:182
#: src/pages/trending.jsx:494
msgid "Go to another instance…"
msgstr ""

#: src/pages/hashtag.jsx:517
#: src/pages/public.jsx:195
#: src/pages/trending.jsx:505
msgid "Go to my instance (<0>{currentInstance}</0>)"
msgstr ""

#: src/pages/home.jsx:210
msgid "Unable to fetch notifications."
msgstr ""

#: src/pages/home.jsx:230
msgid "<0>New</0> <1>Follow Requests</1>"
msgstr ""

#: src/pages/home.jsx:236
msgid "See all"
msgstr ""

#: src/pages/http-route.jsx:68
msgid "Resolving…"
msgstr ""

#: src/pages/http-route.jsx:79
msgid "Unable to resolve URL"
msgstr ""

#: src/pages/list.jsx:108
msgid "Nothing yet."
msgstr ""

#: src/pages/list.jsx:177
#: src/pages/list.jsx:281
msgid "Manage members"
msgstr ""

#. placeholder {0}: account.username
#: src/pages/list.jsx:316
msgid "Remove <0>@{0}</0> from list?"
msgstr ""

#: src/pages/list.jsx:362
msgid "Remove…"
msgstr ""

#. placeholder {0}: lists.length
#: src/pages/lists.jsx:94
msgid "{0, plural, one {# list} other {# lists}}"
msgstr ""

#: src/pages/lists.jsx:109
msgid "No lists yet."
msgstr ""

#: src/pages/login.jsx:110
#: src/pages/login.jsx:123
msgid "Failed to register application"
msgstr "Failed to register application"

#: src/pages/login.jsx:209
msgid "instance domain"
msgstr "instance domain"

#: src/pages/login.jsx:233
msgid "e.g. “mastodon.social”"
msgstr ""

#: src/pages/login.jsx:244
msgid "Failed to log in. Please try again or try another instance."
msgstr ""

#: src/pages/login.jsx:256
msgid "Continue with {selectedInstanceText}"
msgstr ""

#: src/pages/login.jsx:257
msgid "Continue"
msgstr ""

#: src/pages/login.jsx:265
msgid "Don't have an account? Create one!"
msgstr ""

#: src/pages/mentions.jsx:21
msgid "Private mentions"
msgstr ""

#: src/pages/mentions.jsx:160
msgid "Private"
msgstr ""

#: src/pages/mentions.jsx:170
msgid "No one mentioned you :("
msgstr ""

#: src/pages/mentions.jsx:171
msgid "Unable to load mentions."
msgstr ""

#: src/pages/notifications.jsx:104
msgid "You don't follow"
msgstr ""

#: src/pages/notifications.jsx:105
msgid "Who don't follow you"
msgstr ""

#: src/pages/notifications.jsx:106
msgid "With a new account"
msgstr ""

#: src/pages/notifications.jsx:107
msgid "Who unsolicitedly private mention you"
msgstr ""

#: src/pages/notifications.jsx:108
msgid "Who are limited by server moderators"
msgstr ""

#: src/pages/notifications.jsx:578
#: src/pages/notifications.jsx:919
msgid "Notifications settings"
msgstr ""

#: src/pages/notifications.jsx:596
msgid "New notifications"
msgstr ""

#. placeholder {0}: announcements.length
#: src/pages/notifications.jsx:607
msgid "{0, plural, one {Announcement} other {Announcements}}"
msgstr ""

#: src/pages/notifications.jsx:654
#: src/pages/settings.jsx:1232
msgid "Follow requests"
msgstr ""

#. placeholder {0}: followRequests.length
#: src/pages/notifications.jsx:659
msgid "{0, plural, one {# follow request} other {# follow requests}}"
msgstr ""

#. placeholder {0}: notificationsPolicy.summary.pendingRequestsCount
#: src/pages/notifications.jsx:714
msgid "{0, plural, one {Filtered notifications from # person} other {Filtered notifications from # people}}"
msgstr ""

#: src/pages/notifications.jsx:787
msgid "Only mentions"
msgstr ""

#: src/pages/notifications.jsx:791
msgid "Today"
msgstr ""

#: src/pages/notifications.jsx:796
msgid "You're all caught up."
msgstr ""

#: src/pages/notifications.jsx:819
msgid "Yesterday"
msgstr ""

#: src/pages/notifications.jsx:867
msgid "Unable to load notifications"
msgstr ""

#: src/pages/notifications.jsx:946
msgid "Notifications settings updated"
msgstr ""

#: src/pages/notifications.jsx:954
msgid "Filter out notifications from people:"
msgstr ""

#: src/pages/notifications.jsx:968
msgid "Filter"
msgstr ""

#: src/pages/notifications.jsx:971
msgid "Ignore"
msgstr ""

#. placeholder {0}: niceDateTime(updatedAtDate)
#: src/pages/notifications.jsx:1044
msgid "Updated <0>{0}</0>"
msgstr ""

#. placeholder {0}: account.username
#: src/pages/notifications.jsx:1112
msgid "View notifications from <0>@{0}</0>"
msgstr ""

#. placeholder {0}: account.username
#: src/pages/notifications.jsx:1133
msgid "Notifications from <0>@{0}</0>"
msgstr ""

#. placeholder {0}: request.account.username
#: src/pages/notifications.jsx:1201
msgid "Notifications from @{0} will not be filtered from now on."
msgstr ""

#: src/pages/notifications.jsx:1206
msgid "Unable to accept notification request"
msgstr ""

#: src/pages/notifications.jsx:1211
msgid "Allow"
msgstr ""

#. placeholder {1}: request.account.username
#: src/pages/notifications.jsx:1231
msgid "Notifications from @{1} will not show up in Filtered notifications from now on."
msgstr "Notifications from @{1} will not show up in Filtered notifications from now on."

#: src/pages/notifications.jsx:1236
msgid "Unable to dismiss notification request"
msgstr ""

#: src/pages/notifications.jsx:1241
msgid "Dismiss"
msgstr ""

#: src/pages/notifications.jsx:1256
msgid "Dismissed"
msgstr ""

#: src/pages/public.jsx:28
msgid "Local timeline ({instance})"
msgstr ""

#: src/pages/public.jsx:29
msgid "Bubble timeline ({instance})"
msgstr "Bubble timeline ({instance})"

#: src/pages/public.jsx:30
msgid "Federated timeline ({instance})"
msgstr ""

#: src/pages/public.jsx:33
msgid "Local timeline"
msgstr ""

#: src/pages/public.jsx:34
msgid "Bubble timeline"
msgstr "Bubble timeline"

#: src/pages/public.jsx:35
msgid "Federated timeline"
msgstr ""

#: src/pages/public.jsx:115
msgid "No one has posted anything yet."
msgstr ""

#: src/pages/public.jsx:141
msgid "Switch to Local"
msgstr "Switch to Local"

#: src/pages/public.jsx:149
msgid "Switch to Bubble"
msgstr "Switch to Bubble"

#: src/pages/public.jsx:157
msgid "Switch to Federated"
msgstr "Switch to Federated"

#: src/pages/search.jsx:50
msgid "Search: {q} (Posts)"
msgstr ""

#: src/pages/search.jsx:53
msgid "Search: {q} (Accounts)"
msgstr ""

#: src/pages/search.jsx:56
msgid "Search: {q} (Hashtags)"
msgstr ""

#: src/pages/search.jsx:59
msgid "Search: {q}"
msgstr ""

#: src/pages/search.jsx:323
#: src/pages/search.jsx:405
msgid "Hashtags"
msgstr ""

#: src/pages/search.jsx:355
#: src/pages/search.jsx:409
#: src/pages/search.jsx:479
msgid "See more"
msgstr ""

#: src/pages/search.jsx:381
msgid "See more accounts"
msgstr ""

#: src/pages/search.jsx:395
msgid "No accounts found."
msgstr ""

#: src/pages/search.jsx:451
msgid "See more hashtags"
msgstr ""

#: src/pages/search.jsx:465
msgid "No hashtags found."
msgstr ""

#: src/pages/search.jsx:509
msgid "See more posts"
msgstr ""

#: src/pages/search.jsx:523
msgid "No posts found."
msgstr ""

#: src/pages/search.jsx:567
msgid "Enter your search term or paste a URL above to get started."
msgstr ""

#: src/pages/settings.jsx:83
msgid "Settings"
msgstr ""

#: src/pages/settings.jsx:92
msgid "Appearance"
msgstr ""

#: src/pages/settings.jsx:168
msgid "Light"
msgstr ""

#: src/pages/settings.jsx:179
msgid "Dark"
msgstr ""

#: src/pages/settings.jsx:192
msgid "Auto"
msgstr ""

#: src/pages/settings.jsx:202
msgid "Text size"
msgstr ""

#. Preview of one character, in smallest size
#. Preview of one character, in largest size
#: src/pages/settings.jsx:207
#: src/pages/settings.jsx:232
msgid "A"
msgstr ""

#: src/pages/settings.jsx:246
msgid "Display language"
msgstr ""

#: src/pages/settings.jsx:255
msgid "Volunteer translations"
msgstr "Volunteer translations"

#: src/pages/settings.jsx:266
msgid "Posting"
msgstr ""

#: src/pages/settings.jsx:273
msgid "Default visibility"
msgstr ""

#: src/pages/settings.jsx:274
#: src/pages/settings.jsx:320
msgid "Synced"
msgstr ""

#: src/pages/settings.jsx:299
msgid "Failed to update posting privacy"
msgstr ""

#: src/pages/settings.jsx:322
msgid "Synced to your instance server's settings. <0>Go to your instance ({instance}) for more settings.</0>"
msgstr ""

#: src/pages/settings.jsx:337
msgid "Experiments"
msgstr ""

#: src/pages/settings.jsx:350
msgid "Auto refresh timeline posts"
msgstr ""

#: src/pages/settings.jsx:362
msgid "Boosts carousel"
msgstr ""

#: src/pages/settings.jsx:378
msgid "Post translation"
msgstr ""

#: src/pages/settings.jsx:389
msgid "Translate to "
msgstr "Translate to "

#: src/pages/settings.jsx:400
msgid "System language ({systemTargetLanguageText})"
msgstr ""

#. placeholder {0}: snapStates.settings.contentTranslationHideLanguages.length
#: src/pages/settings.jsx:426
msgid "{0, plural, =0 {Hide \"Translate\" button for:} other {Hide \"Translate\" button for (#):}}"
msgstr ""

#: src/pages/settings.jsx:480
msgid "Note: This feature uses external translation services, powered by <0>Lingva API</0> & <1>Lingva Translate</1>."
msgstr ""

#: src/pages/settings.jsx:514
msgid "Auto inline translation"
msgstr ""

#: src/pages/settings.jsx:518
msgid "Automatically show translation for posts in timeline. Only works for <0>short</0> posts without content warning, media and poll."
msgstr ""

#: src/pages/settings.jsx:538
msgid "GIF Picker for composer"
msgstr ""

#: src/pages/settings.jsx:542
msgid "Note: This feature uses external GIF search service, powered by <0>GIPHY</0>. G-rated (suitable for viewing by all ages), tracking parameters are stripped, referrer information is omitted from requests, but search queries and IP address information will still reach their servers."
msgstr ""

#: src/pages/settings.jsx:571
msgid "Image description generator"
msgstr ""

#: src/pages/settings.jsx:576
msgid "Only for new images while composing new posts."
msgstr ""

#: src/pages/settings.jsx:583
msgid "Note: This feature uses external AI service, powered by <0>img-alt-api</0>. May not work well. Only for images and in English."
msgstr ""

#: src/pages/settings.jsx:609
msgid "Server-side grouped notifications"
msgstr ""

#: src/pages/settings.jsx:613
msgid "Alpha-stage feature. Potentially improved grouping window but basic grouping logic."
msgstr ""

#: src/pages/settings.jsx:634
msgid "\"Cloud\" import/export for shortcuts settings"
msgstr ""

#: src/pages/settings.jsx:639
msgid "⚠️⚠️⚠️ Very experimental.<0/>Stored in your own profile’s notes. Profile (private) notes are mainly used for other profiles, and hidden for own profile."
msgstr ""

#: src/pages/settings.jsx:650
msgid "Note: This feature uses currently-logged-in instance server API."
msgstr ""

#: src/pages/settings.jsx:667
msgid "Cloak mode <0>(<1>Text</1> → <2>████</2>)</0>"
msgstr ""

#: src/pages/settings.jsx:676
msgid "Replace text as blocks, useful when taking screenshots, for privacy reasons."
msgstr ""

#: src/pages/settings.jsx:692
msgid "Numberless mode"
msgstr "Numberless mode"

#: src/pages/settings.jsx:696
msgid "Removes all post and user metrics from the client."
msgstr "Removes all post and user metrics from the client."

#: src/pages/settings.jsx:705
msgid "Regex mutes"
msgstr "Regex mutes"

#: src/pages/settings.jsx:719
msgid "Mute posts matching these regular expressions. One per line. Make sure that the final regular expression is terminated by a newline character, or it won't be parsed!"
msgstr "Mute posts matching these regular expressions. One per line. Make sure that the final regular expression is terminated by a newline character, or it won't be parsed!"

#: src/pages/settings.jsx:745
msgid "About"
msgstr ""

#: src/pages/settings.jsx:784
msgid "<0>Built</0> by <1>@cheeaun</1>"
msgstr ""

#: src/pages/settings.jsx:806
msgid "<0><1>Modified</1> by <2>@steffo</2> & others</0>"
msgstr "<0><1>Modified</1> by <2>@steffo</2> & others</0>"

#: src/pages/settings.jsx:838
msgid "Sponsor"
msgstr ""

#: src/pages/settings.jsx:846
msgid "Donate"
msgstr "Donate"

#: src/pages/settings.jsx:862
msgid "Privacy Policy"
msgstr ""

#. placeholder {0}: WEBSITE.replace(/https?:\/\//g, '').replace(/\/$/, '')
#: src/pages/settings.jsx:869
msgid "<0>Site:</0> {0}"
msgstr ""

#. placeholder {0}: !__FAKE_COMMIT_HASH__ && ( <span class="ib insignificant"> ( <a href={`https://github.com/cheeaun/phanpy/commit/${__COMMIT_HASH__}`} target="_blank" rel="noopener noreferrer" > <RelativeTime datetime={new Date(__BUILD_TIME__)} /> </a> ) </span> )
#: src/pages/settings.jsx:876
msgid "<0>Version:</0> <1/> {0}"
msgstr ""

#: src/pages/settings.jsx:891
msgid "Version string copied"
msgstr ""

#: src/pages/settings.jsx:894
msgid "Unable to copy version string"
msgstr ""

#: src/pages/settings.jsx:1129
#: src/pages/settings.jsx:1134
msgid "Failed to update subscription. Please try again."
msgstr ""

#: src/pages/settings.jsx:1140
msgid "Failed to remove subscription. Please try again."
msgstr ""

#: src/pages/settings.jsx:1147
msgid "Push Notifications (beta)"
msgstr ""

#: src/pages/settings.jsx:1169
msgid "Push notifications are blocked. Please enable them in your browser settings."
msgstr ""

#. placeholder {0}: [ { value: 'all', label: t`anyone`, }, { value: 'followed', label: t`people I follow`, }, { value: 'follower', label: t`followers`, }, ].map((type) => ( <option value={type.value}>{type.label}</option> ))
#: src/pages/settings.jsx:1178
msgid "Allow from <0>{0}</0>"
msgstr ""

#: src/pages/settings.jsx:1187
msgid "anyone"
msgstr ""

#: src/pages/settings.jsx:1191
msgid "people I follow"
msgstr ""

#: src/pages/settings.jsx:1195
msgid "followers"
msgstr ""

#: src/pages/settings.jsx:1228
msgid "Follows"
msgstr ""

#: src/pages/settings.jsx:1236
msgid "Polls"
msgstr ""

#: src/pages/settings.jsx:1240
msgid "Post edits"
msgstr ""

#: src/pages/settings.jsx:1261
msgid "Push permission was not granted since your last login. You'll need to <0><1>log in</1> again to grant push permission</0>."
msgstr ""

#: src/pages/settings.jsx:1277
msgid "NOTE: Push notifications only work for <0>one account</0>."
msgstr ""

#. js-lingui-explicit-id
#: src/pages/status.jsx:599
#: src/pages/status.jsx:1133
msgid "post.title"
msgstr "Post"

#: src/pages/status.jsx:824
msgid "You're not logged in. Interactions (reply, boost, etc) are not possible."
msgstr ""

#: src/pages/status.jsx:844
msgid "This post is from another instance (<0>{instance}</0>). Interactions (reply, boost, etc) are not possible."
msgstr ""

#: src/pages/status.jsx:872
msgid "Error: {e}"
msgstr ""

#: src/pages/status.jsx:879
msgid "Switch to my instance to enable interactions"
msgstr ""

#: src/pages/status.jsx:981
msgid "Unable to load replies."
msgstr ""

#: src/pages/status.jsx:1093
msgid "Back"
msgstr ""

#: src/pages/status.jsx:1124
msgid "Go to main post"
msgstr ""

#. placeholder {0}: ancestors.length
#: src/pages/status.jsx:1147
msgid "{0} posts above ‒ Go to top"
msgstr ""

#: src/pages/status.jsx:1190
#: src/pages/status.jsx:1253
msgid "Switch to Side Peek view"
msgstr ""

#: src/pages/status.jsx:1254
msgid "Switch to Full view"
msgstr ""

#: src/pages/status.jsx:1272
msgid "Show all sensitive content"
msgstr ""

#: src/pages/status.jsx:1277
msgid "Experimental"
msgstr ""

#: src/pages/status.jsx:1286
msgid "Unable to switch"
msgstr ""

#. placeholder {1}: punycode.toUnicode( postInstance, )
#: src/pages/status.jsx:1293
msgid "Switch to post's instance ({1})"
msgstr "Switch to post's instance ({1})"

#: src/pages/status.jsx:1296
msgid "Switch to post's instance"
msgstr ""

#: src/pages/status.jsx:1354
msgid "Unable to load post"
msgstr ""

#. placeholder {0}: replies.length
#. placeholder {1}: shortenNumber(replies.length)
#: src/pages/status.jsx:1490
msgid "{0, plural, one {# reply} other {<0>{1}</0> replies}}"
msgstr ""

#. placeholder {0}: shortenNumber(totalComments)
#: src/pages/status.jsx:1508
msgid "{totalComments, plural, one {# comment} other {<0>{0}</0> comments}}"
msgstr ""

#: src/pages/status.jsx:1530
msgid "View post with its replies"
msgstr ""

#: src/pages/trending.jsx:72
msgid "Trending ({instance})"
msgstr ""

#: src/pages/trending.jsx:229
msgid "Trending News"
msgstr ""

#. By [Author]
#. placeholder {0}: author ? ( <NameText account={author} showAvatar /> ) : authorUrl ? ( <a href={authorUrl} target="_blank" rel="noopener noreferrer" > {authorName} </a> ) : ( authorName )
#: src/pages/trending.jsx:348
msgid "By {0}"
msgstr "By {0}"

#: src/pages/trending.jsx:409
msgid "Back to showing trending posts"
msgstr ""

#. placeholder {0}: currentLink .replace(/^https?:\/\/(www\.)?/i, '') .replace(/\/$/, '')
#: src/pages/trending.jsx:414
msgid "Showing posts mentioning <0>{0}</0>"
msgstr ""

#: src/pages/trending.jsx:426
msgid "Trending posts"
msgstr ""

#: src/pages/trending.jsx:449
msgid "No trending posts."
msgstr ""

#: src/pages/trending.jsx:450
msgid "Your server does not seem to support the Trending timeline"
msgstr "Your server does not seem to support the Trending timeline"

#: src/pages/welcome.jsx:54
msgid "A minimalistic opinionated Mastodon web client."
msgstr ""

#: src/pages/welcome.jsx:65
msgid "Log in with Mastodon"
msgstr ""

#: src/pages/welcome.jsx:71
msgid "Sign up"
msgstr ""

#: src/pages/welcome.jsx:78
msgid "Connect your existing Mastodon/Fediverse account.<0/>Your credentials are not stored on this server."
msgstr ""

#: src/pages/welcome.jsx:95
msgid "<0>Built</0> by <1>@cheeaun</1>. <2>Privacy Policy</2>."
msgstr ""

#: src/pages/welcome.jsx:126
msgid "Screenshot of Boosts Carousel"
msgstr ""

#: src/pages/welcome.jsx:130
msgid "Boosts Carousel"
msgstr ""

#: src/pages/welcome.jsx:133
msgid "Visually separate original posts and re-shared posts (boosted posts)."
msgstr ""

#: src/pages/welcome.jsx:142
msgid "Screenshot of nested comments thread"
msgstr ""

#: src/pages/welcome.jsx:146
msgid "Nested comments thread"
msgstr ""

#: src/pages/welcome.jsx:149
msgid "Effortlessly follow conversations. Semi-collapsible replies."
msgstr ""

#: src/pages/welcome.jsx:157
msgid "Screenshot of grouped notifications"
msgstr ""

#: src/pages/welcome.jsx:161
msgid "Grouped notifications"
msgstr ""

#: src/pages/welcome.jsx:164
msgid "Similar notifications are grouped and collapsed to reduce clutter."
msgstr ""

#: src/pages/welcome.jsx:173
msgid "Screenshot of multi-column UI"
msgstr ""

#: src/pages/welcome.jsx:177
msgid "Single or multi-column"
msgstr ""

#: src/pages/welcome.jsx:180
msgid "By default, single column for zen-mode seekers. Configurable multi-column for power users."
msgstr ""

#: src/pages/welcome.jsx:189
msgid "Screenshot of multi-hashtag timeline with a form to add more hashtags"
msgstr ""

#: src/pages/welcome.jsx:193
msgid "Multi-hashtag timeline"
msgstr ""

#: src/pages/welcome.jsx:196
msgid "Up to 5 hashtags combined into a single timeline."
msgstr ""

#: src/utils/open-compose.js:24
msgid "Looks like your browser is blocking popups."
msgstr ""

#: src/utils/shorten-number.js:8
msgid "Several"
msgstr "Several"

#: src/utils/show-compose.js:16
msgid "A draft post is currently minimized. Post or discard it before creating a new one."
msgstr ""

#: src/utils/show-compose.js:21
msgid "A post is currently open. Post or discard it before creating a new one."
msgstr ""
